import type { FC } from 'react';
import { Xmark } from '@gravity-ui/icons';
import { useTranslation } from 'next-i18next';
import { InputStyle } from './styles';
import { BaseInput } from '../base';
import { Button } from '../Button';

type Props = {
  setIsOpen: (isOpen: boolean) => void
  onSearch: (text: string) => void
}

export const SearchInput: FC<Props> = ({ setIsOpen, onSearch }) => {
  const { t } = useTranslation();

  return (
    <BaseInput>
      <InputStyle
        placeholder={t('placeholder.search').toString()}
        onChange={(e) => onSearch(e.target.value)}
        autoFocus
      />
      <Button size="small" view="ghost" onClick={() => setIsOpen(false)}>
        <Xmark />
      </Button>
    </BaseInput>
  );
};
