import { FC, useMemo, useState } from 'react';
import { ChevronLeft, ChevronRight } from '@gravity-ui/icons';
import {
  Container,
  StyledList,
  StyledItem,
  Image,
  StyledImageContainer,
  Aside,
  StyledItemImage, StyledRightButton, StyledLeftButton, AsideLeftArrow, AsideRightArrow,
} from './styles';

interface Props {
  images: string[]
}

export const ImageSlider: FC<Props> = ({ images }) => {
  const imageLink = useMemo(() => (
    images && images?.length > 0 ? images[0] : null
  ), [images]);
  const [activeImg, setActiveImg] = useState(imageLink);

  const handlePrevClick = () => {
    const currentIndex = images.indexOf(activeImg as string);
    const newIndex = currentIndex > 0 ? currentIndex - 1 : images.length - 1;
    setActiveImg(images[newIndex]);
  };

  const handleNextClick = () => {
    const currentIndex = images.indexOf(activeImg as string);
    const newIndex = currentIndex < images.length - 1 ? currentIndex + 1 : 0;
    setActiveImg(images[newIndex]);
  };

  return (
    <Container>
      <AsideLeftArrow onClick={handlePrevClick}>
        <ChevronLeft width="16px" height="16px" color="#8A80FF" />
      </AsideLeftArrow>
      <Aside>
        <StyledList>
          {images?.map((image) => (
            <StyledItem key={image} type="button" onClick={() => setActiveImg(image)} $active={image === activeImg}>
              <StyledItemImage key={image} src={image} />
            </StyledItem>
          ))}
        </StyledList>
      </Aside>
      <AsideRightArrow onClick={handleNextClick}>
        <ChevronRight width="16px" height="16px" color="#8A80FF" />
      </AsideRightArrow>
      <StyledImageContainer>
        <StyledLeftButton onClick={handlePrevClick}>
          <ChevronLeft color="#8A80FF" />
        </StyledLeftButton>
        <Image src={activeImg as string} />
        <StyledRightButton onClick={handleNextClick}>
          <ChevronRight color="#8A80FF" />
        </StyledRightButton>
      </StyledImageContainer>
    </Container>
  );
};
