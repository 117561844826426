/* eslint-disable */
export default function IconUploadArrow() {
  return (
    <svg width="40" height="48" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="14" width="12" height="32" rx="1" fill="#235AFF"/>
      <path
        d="M1.6092 28.6897C1.00285 28.053 1.45414 27 2.33333 27H37.6667C38.5459 27 38.9971 28.053 38.3908 28.6897L20.7241 47.2397C20.33 47.6535 19.67 47.6534 19.2759 47.2397L1.6092 28.6897Z"
        fill="#235AFF"/>
    </svg>
  )
}
