import styled from 'styled-components';

export const Container = styled.article`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
`;

export const Info = styled.div`
  > a {
    &:before {
      content: "";
      width: 100%;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
    }
  }
  span {
    opacity: 0.3;
    font-size: 12px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
`;
