import styled, { css } from 'styled-components';
import { GlobalColors } from '../../GlobalStyle';

export const StyledList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow: auto;
`;

export const StyledItem = styled.li<{ $disabled?: boolean, $isSelected?: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px;
  border-radius: 8px;
  outline: none;
  cursor: pointer;

  ${({ $isSelected }) => $isSelected && css`
    background: ${GlobalColors.bg.darkBlue.outlineHover};
  `};
    
  ${({ $disabled }) => $disabled && css`
    pointer-events: none;
    color: ${GlobalColors.text.disable};
    background: none;
  `};
    
  &:hover {
    background-color: ${GlobalColors.bg.darkBlue.disable};
  }
`;

export const StyledText = styled.p`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LoaderWrapper = styled.div<{ $width?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 96px;
  width: ${({ $width }) => ($width ? `${$width}px` : 'auto')};
`;
