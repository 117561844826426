import styled from 'styled-components';

export const StyleList = styled.ul`
  display: flex;
  position: absolute;
  flex-direction: column;
  list-style: none;
  padding: 8px;
  margin: 0;
  width: 100%;
  gap: 8px;
  box-sizing: border-box;
`;
