import React, { FC, ReactNode, TextareaHTMLAttributes } from 'react';
import { DefaultTFuncReturn } from 'i18next';
import { Input as BaseInput, InputInputSlotPropsOverrides, InputOwnerState } from '@mui/base/Input';
import { SlotComponentProps } from '@mui/base';
import {
  Wrapper, Label, ButtonsGroup, Message, Container, TopBlock, TextareaElement,
} from './styled';
import type { TextareaSize, StatusType } from './types';

export interface TextareaProps extends Omit<TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  $size?: TextareaSize;
  label?: string | DefaultTFuncReturn;
  width?: string
  minRows?: number
  name: string;
  buttons?: ReactNode[]; // right top buttons
  disabled?: boolean
  readonly?: boolean
  onChange?: (val: string) => void
  message?: string; // bottom system message
  status?: StatusType
}

export const Textarea: FC<TextareaProps> = ({
  $size = 'l',
  label = null,
  width,
  name,
  buttons = false,
  disabled = false,
  readonly = false,
  minRows = 3,
  message,
  status = 'default',
  onBlur,
  onChange,
  ...rest
}) => (
  <Wrapper $width={width ?? '100%'}>
    <TopBlock>
      <Label $disabled={disabled}>{label}</Label>

      {buttons && <ButtonsGroup>{buttons}</ButtonsGroup>}
    </TopBlock>

    <BaseInput
      multiline
      name={name}
      data-status={status}
      data-disabled={disabled}
      data-size={$size}
      data-readonly={readonly}
      slots={{
        root: Container,
        input: 'input',
        textarea: TextareaElement,
      }}
      slotProps={{
        input:
              { ...(rest as SlotComponentProps<'input',
                    InputInputSlotPropsOverrides,
                    InputOwnerState> | undefined) },
      }}
      onBlur={onBlur}
      onChange={(e) => onChange?.(e.target.value)}
      maxRows={6}
      minRows={minRows}
    />

    <Message
      $status={status}
      $disabled={disabled}
    >
      {message}
    </Message>
  </Wrapper>
);
