import { GlobalColors } from '../GlobalStyle';

export const darkTheme = {
  focus: GlobalColors.border.focus,
  border: GlobalColors.border.primary,
  primary: {
    primaryDefault: GlobalColors.bg.gray.primary,
    primaryHover: GlobalColors.bg.gray.primaryHover,
    primaryClick: GlobalColors.bg.gray.primaryActive,
    primaryText: GlobalColors.text.primaryOnColor,
  },
  secondary: {
    secondaryDefault: GlobalColors.bg.darkBlue.secondary,
    secondaryHover: GlobalColors.bg.darkBlue.secondaryHover,
    secondaryClick: GlobalColors.bg.darkBlue.secondaryActive,
    secondaryText: GlobalColors.text.secondaryButton,
  },
  outline: {
    outlineDefault: 'transparent',
    outlineHover: GlobalColors.bg.darkBlue.outlineHover,
    outlineClick: GlobalColors.bg.darkBlue.outlineActive,
    outlineText: GlobalColors.text.secondaryButton,
  },
  ghost: {
    ghostDefault: 'transparent',
    ghostHover: GlobalColors.bg.darkBlue.ghostHover,
    ghostClick: GlobalColors.bg.darkBlue.ghostActive,
    ghostText: GlobalColors.text.ghost,
  },
  link: {
    linkDefault: 'transparent',
    linkText: GlobalColors.text.accent,
  },
};

export const blueTheme = {
  focus: GlobalColors.border.focus,
  border: GlobalColors.border.activeSecondary,
  primary: {
    primaryDefault: GlobalColors.bg.blue.primary,
    primaryHover: GlobalColors.bg.blue.primaryHover,
    primaryClick: GlobalColors.bg.blue.primaryActive,
    primaryText: GlobalColors.text.primaryOnColor,
  },
  secondary: {
    secondaryDefault: GlobalColors.bg.blue.secondary,
    secondaryHover: GlobalColors.bg.blue.secondaryHover,
    secondaryClick: GlobalColors.bg.blue.secondaryActive,
    secondaryText: GlobalColors.text.accent,
  },
  outline: {
    outlineDefault: 'transparent',
    outlineHover: GlobalColors.bg.blue.outlineHover,
    outlineClick: GlobalColors.bg.blue.outlineActive,
    outlineText: GlobalColors.text.accent,
  },
  ghost: {
    ghostDefault: 'transparent',
    ghostHover: GlobalColors.bg.blue.ghostHover,
    ghostClick: GlobalColors.bg.blue.ghostActive,
    ghostText: GlobalColors.text.accent,
  },
};

export const yellowTheme = {
  focus: GlobalColors.border.focusWarning,
  border: GlobalColors.border.focusWarning,
  primary: {
    primaryDefault: GlobalColors.bg.warning.primary,
    primaryHover: GlobalColors.bg.warning.primaryHover,
    primaryClick: GlobalColors.bg.warning.primaryActive,
    primaryText: GlobalColors.text.warning,
  },
  secondary: {
    secondaryDefault: GlobalColors.bg.warning.secondary,
    secondaryHover: GlobalColors.bg.warning.secondaryHover,
    secondaryClick: GlobalColors.bg.warning.secondaryActive,
    secondaryText: GlobalColors.text.warning,
  },
  outline: {
    outlineDefault: 'transparent',
    outlineHover: GlobalColors.bg.warning.outlineHover,
    outlineClick: GlobalColors.bg.warning.outlineActive,
    outlineText: GlobalColors.text.warning,
  },
  ghost: {
    ghostDefault: 'transparent',
    ghostHover: GlobalColors.bg.warning.ghostHover,
    ghostClick: GlobalColors.bg.warning.ghostActive,
    ghostText: GlobalColors.text.warning,
  },
};

export const greenTheme = {
  focus: GlobalColors.border.focusSuccess,
  border: GlobalColors.border.successSecondary,
  primary: {
    primaryDefault: GlobalColors.bg.success.primary,
    primaryHover: GlobalColors.bg.success.primaryHover,
    primaryClick: GlobalColors.bg.success.primaryActive,
    primaryText: GlobalColors.text.primaryOnColor,
  },
  secondary: {
    secondaryDefault: GlobalColors.bg.success.secondary,
    secondaryHover: GlobalColors.bg.success.secondaryHover,
    secondaryClick: GlobalColors.bg.success.secondaryActive,
    secondaryText: GlobalColors.text.success,
  },
  outline: {
    outlineDefault: 'transparent',
    outlineHover: GlobalColors.bg.success.outlineHover,
    outlineClick: GlobalColors.bg.success.outlineActive,
    outlineText: GlobalColors.text.success,
  },
  ghost: {
    ghostDefault: 'transparent',
    ghostHover: GlobalColors.bg.success.ghostHover,
    ghostClick: GlobalColors.bg.success.ghostActive,
    ghostText: GlobalColors.text.success,
  },
};

export const redTheme = {
  focus: GlobalColors.border.focusError,
  border: GlobalColors.border.error,
  primary: {
    primaryDefault: GlobalColors.bg.error.primary,
    primaryHover: GlobalColors.bg.error.primaryHover,
    primaryClick: GlobalColors.bg.error.primaryActive,
    primaryText: GlobalColors.text.primaryOnColor,
  },
  secondary: {
    secondaryDefault: GlobalColors.bg.error.secondary,
    secondaryHover: GlobalColors.bg.error.secondaryHover,
    secondaryClick: GlobalColors.bg.error.secondaryActive,
    secondaryText: GlobalColors.text.error,
  },
  outline: {
    outlineDefault: 'transparent',
    outlineHover: GlobalColors.bg.error.outlineHover,
    outlineClick: GlobalColors.bg.error.outlineActive,
    outlineText: GlobalColors.text.error,
  },
  ghost: {
    ghostDefault: 'transparent',
    ghostHover: GlobalColors.bg.error.ghostHover,
    ghostClick: GlobalColors.bg.error.ghostActive,
    ghostText: GlobalColors.text.error,
  },
};
