import React, { useCallback, useMemo, useRef } from 'react';
import type { AdvancedProps } from '../index';
import { SelectorButton } from './SelectorButton';
import { Popup, PopupRef } from '../../Popup';
import { SelectorList } from '../list';

export const AdvancedSelector = <T extends string = string>({
  width,
  value,
  options,
  icon = 'avatar',
  placeholder,
  onChange,
}: Omit<AdvancedProps<T>, 'type'>) => {
  const selectorValue = useMemo(() => (value ? ({
    id: value.value,
    icon: value.icon,
    text: value.name,
    isSelected: value.checked,
    disabled: value.disabled,
    customIcon: value.customIcon,
  }) : undefined), [value]);

  const list = useMemo(() => options.map((option) => ({
    id: option.value,
    icon: option.icon,
    text: option.name,
    isSelected: option.checked,
    disabled: option.disabled,
    customIcon: option.customIcon,
  })), [options]);

  const ref = useRef<PopupRef>(null);

  const handleChange = useCallback((id: T) => {
    onChange(id);
    ref?.current?.onClose();
  }, [onChange]);

  return (
    <Popup
      ref={ref}
      width={width}
      trigger={(
        <SelectorButton
          icon={icon}
          value={selectorValue}
          placeholder={placeholder}
        />
      )}
      spaceStrategy="no-space"
      stretchToReference
    >
      <SelectorList<T> list={list} onSelect={handleChange} icon={icon} />
    </Popup>
  );
};
