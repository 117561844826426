/* eslint-disable */
import React, { FC, ReactNode, memo } from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { ModalProvider } from "./ModalContext";

interface Props {
   isOpen?: boolean
   onClose: () => void
   $customWidth?: string
   title?: string | null
   formComponent?: ReactNode
   isCentered?: boolean
   maxW?: string
}

export const ModalBlock: FC<Props> = memo(({
    isOpen = false,
    onClose,
    $customWidth,
    formComponent,
    title,
    isCentered = true,
    maxW,
}) => {

    return (
        <Modal
            onClose={onClose}
            isOpen={isOpen}
            motionPreset="slideInBottom"
        >
            <ModalOverlay />
            <ModalContent width={$customWidth ?? '100%'} maxW={maxW} style={{ marginRight: '12px', marginLeft: '12px' }}>
                <ModalHeader>{title}</ModalHeader>
                <ModalCloseButton />
                <ModalProvider value={{ toggleVisibility: onClose }} >
                    <ModalBody style={{ paddingBottom: 24 }} width={$customWidth ?? '100%'}>
                        {formComponent}
                    </ModalBody>
                </ModalProvider>
            </ModalContent>
        </Modal>
    );})
