import styled, { css } from 'styled-components';
import { GlobalColors } from '../../GlobalStyle';

const wrapperStyle = css`
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 104px;
    padding: 12px;
    border-radius: 12px;
    gap: 8px;
    z-index: 3;
    background: ${GlobalColors.bg.main.thirdly};
`;

export const Wrapper = styled.label<{ $width: string, $isSelected: boolean }>`
  width: ${({ $width }) => $width};
  border: 2px dashed ${({ $isSelected }) => ($isSelected
    ? 'transparent'
    : GlobalColors.border.primary)};
  ${wrapperStyle};
    
  &:hover {
    cursor: pointer;
    border: 2px dashed ${GlobalColors.border.focus};
  }
`;

export const WrapperLoaded = styled.div<{ $width: string, $isSelected: boolean }>`
  width: ${({ $width }) => $width};
  border: 2px dashed ${({ $isSelected }) => ($isSelected
    ? 'transparent'
    : GlobalColors.border.primary)};
  ${wrapperStyle};
    
  &:hover {
    border: 2px dashed ${GlobalColors.border.focus};
  }
`;

export const FileStyle = styled.button`
  width: auto;
  margin: 0 auto;
  background: ${GlobalColors.bg.blue.secondary};
  display: flex;
  height: 24px;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
  color: ${GlobalColors.text.accent};
`;

export const IconStyle = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  color: ${GlobalColors.text.accent};
`;

export const ErrorMassage = styled.div`
  color: ${GlobalColors.text.error};
  margin-top: -2px;
  font-size: 12px;
`;

export const TextStyle = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    & > span {
        color: ${GlobalColors.text.accent};
        margin-left: 4px;
    }
`;

export const SubTextStyle = styled.span`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    color: ${GlobalColors.text.secondary};
`;

export const FileInput = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;

export const SubText = styled.span<{ $isDisabled: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;;
`;
