import { FC, useCallback, useRef, useState } from 'react';
import { ChevronLeft, ChevronRight } from '@gravity-ui/icons';
import {
  ImageBlock, ImageList, ImageButton, Wrapper, NavBlock, ArrowButton, Slide, Slides,
} from './styles';

interface Props {
  images: string[];
}

export const Swiper: FC<Props> = ({ images }) => {
  const [slidesCount] = useState(images.length);
  const [currentIndex, setCurrentIndex] = useState(0);
  const ref = useRef<Record<string, HTMLDivElement>>({});

  const scrollToView = (slideIndex: number) => {
    if (ref.current[slideIndex]) {
      ref.current[slideIndex].scrollIntoView(false);
      setCurrentIndex(slideIndex);
    }
  };

  const nextHandler = useCallback(() => {
    const nextImage = currentIndex + 1;
    if (nextImage <= slidesCount) {
      scrollToView(nextImage);
    }
  }, [currentIndex, slidesCount]);

  const prevHandler = useCallback(() => {
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      scrollToView(prevIndex);
    }
  }, [currentIndex]);

  return (
    <Wrapper>
      {images.length > 1
          && (
          <NavBlock>
            <ArrowButton
              onClick={prevHandler}
              $isDisabled={currentIndex === 0}
            >
              <ChevronLeft width={24} height={24} />
            </ArrowButton>

            <ArrowButton
              onClick={nextHandler}
              $isDisabled={currentIndex === slidesCount - 1}
            >
              <ChevronRight width={24} height={24} />
            </ArrowButton>
          </NavBlock>
          )}

      <ImageBlock>
        <Slides>
          {images.length > 1
            ? images.map((src: string, index) => (
              <Slide key={src} ref={(el: HTMLDivElement) => { ref.current[index] = el; }}>
                <img alt="pic" src={src} />
              </Slide>
            ))
            : (
              <Slide>
                <img alt="pic" src={images[0]} />
              </Slide>
            )}
        </Slides>
      </ImageBlock>

      <ImageList>
        {images.length > 1 ? images.map((src: string, index) => (
          <ImageButton
            type="button"
            $isActive={currentIndex === index}
            key={src}
            onClick={() => scrollToView(index)}
          >
            <img alt="pic" src={src} />
          </ImageButton>
        )) : null}
      </ImageList>

    </Wrapper>
  );
};
