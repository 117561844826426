import styled from 'styled-components';
import { styled as styledMui } from '@mui/system';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import type { TextareaSize, StatusType } from './types';
import { GlobalColors } from '../GlobalStyle';

interface WidthProps {
  $width?: string
}
interface DisabledProps {
  $disabled?: boolean
}
interface StatusProps {
  $status?: StatusType
}
interface RootProps {
  'data-size'?: TextareaSize
  'data-status'?: StatusType
  'data-disabled'?: boolean
  'data-readonly'?: boolean
}

export const Wrapper = styled.div<WidthProps>`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: ${({ $width }) => $width};
`;

export const Message = styled.div<DisabledProps & StatusProps>`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  
  color: ${({ $status }) => {
    switch ($status) {
      case 'error':
        return GlobalColors.text.error;
      case 'success':
        return GlobalColors.text.success;
      case 'default':
      default:
        return GlobalColors.text.secondary;
    }
  }};
  color: ${({ $disabled }) => $disabled && GlobalColors.text.disable};
};
`;

export const TopBlock = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;

export const ButtonsGroup = styled.div`
    color: ${GlobalColors.icon.ghost};
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
`;

export const Label = styled.label<DisabledProps>`
    color: ${({ $disabled }) => ($disabled
    ? GlobalColors.text.disable
    : GlobalColors.text.primary)
};
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    align-self: center;
    white-space: nowrap;
`;

export const Container = styledMui('div')`
  display: flex;
  align-items: center;
  border: 1px solid ${GlobalColors.border.primary};
  width: 100%;
  border-radius: 8px;
  line-height: 18px;
  background: ${GlobalColors.bg.main.secondary};
  transition: ease-out 0.100ms;
  
  ${(props: RootProps) => {
    switch (props['data-size']) {
      case 'sm':
        return 'font-size: 14px;';
      case 'l':
      default:
        return 'font-size: 16px;';
    }
  }}

  &:hover {
    border: 1px solid ${GlobalColors.border.primaryHover};
  }
  
  &:focus-within {
    outline: 2px solid ${GlobalColors.border.focus};
    &:hover {
      border: 1px solid ${GlobalColors.border.primary};
    }
  }
  
  ${(props: RootProps) => {
    switch (props['data-status']) {
      case 'error':
        return `
          border: 1px solid ${GlobalColors.border.error};
          color:  ${GlobalColors.text.error};
        `;
      case 'success':
        return `
          border: 1px solid ${GlobalColors.border.success};
          color:  ${GlobalColors.text.success};
        `;
      default:
        return null;
    }
  }
}
  
  ${(props: RootProps) => (props['data-disabled']
    && `
      background-color: ${GlobalColors.bg.darkBlue.disable};
      border: none;
      color: ${GlobalColors.text.disable};
      pointer-events: none;
  `)
}
  
  ${(props: RootProps) => (props['data-readonly']
    && `
      background-color: ${GlobalColors.bg.darkBlue.disable};
      border: none;
      pointer-events: none;
  `)}
`;

export const TextareaElement = styledMui(TextareaAutosize)`
  width: 100%;
  resize: none;
  padding: 6px 8px;
  color: ${GlobalColors.text.primary};
  background-color: transparent;
  border: 0;

  &:focus {
    border: 0;
    outline: none;
  }

  &::placeholder {
    color: ${GlobalColors.text.primary};
  }

  &::-webkit-scrollbar {
    width: 1px;
  }

  &:disabled {
    pointer-events: none;
    color: ${GlobalColors.text.disable};
  }
`;
