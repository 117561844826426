import styled from 'styled-components';

const getColor = ({ ...props }) => {
  if (props.isDragAccept) {
    return '#235AFF';
  }
  if (props.isDragReject) {
    return '#ff1744';
  }
  if (props.isFocused) {
    return '#2196f3';
  }
  return '#eeeeee';
};

export const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const ContainerInput = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100%;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: rgba(255, 255, 255, 0.95);
  outline: none;
  transition: border .24s ease-in-out;
  
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #235AFF;
    padding-top: 8px;
  }
`;
