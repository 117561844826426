import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { ChevronDown } from '@gravity-ui/icons';
import { StyleAccordionButton, StyleQuantity } from './styles';

interface Props {
  label: string
  icon?: ReactNode
  visible?: boolean
  children: ReactNode
  quantity?: number
}

export const AccordionItem: FC<Props> = ({ label, icon, visible = false, children, quantity = 0 }) => {
  const [isVisible, setIsVisible] = useState(visible);

  return (
    <>
      <StyleAccordionButton role="button" onClick={() => setIsVisible((prevState) => !prevState)}>
        <div style={{ display: 'flex', flexWrap: 'nowrap', gap: 8, alignItems: 'center' }}>
          {icon}
          <h2>{label}</h2>
          {quantity > 0 && <StyleQuantity>{quantity}</StyleQuantity>}
        </div>
        <ChevronDown color="#B2BBC6" style={{ transform: isVisible ? 'rotate(180deg)' : 'none' }} />
      </StyleAccordionButton>
      <div style={{ display: isVisible ? 'block' : 'none', paddingBottom: 16 }}>
        { children }
      </div>
    </>
  );
};
