import styled from 'styled-components';
import { GlobalColors } from '../../GlobalStyle';

export const StyledPopupLayout = styled.div`
  align-self: center;
  z-index: 200;
`;

export const StyledPopup = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
  align-items: flex-start;
  border-radius: 12px;
  background: ${GlobalColors.bg.main.secondary};
  box-shadow: 0 12px 24px 0 rgba(22, 34, 51, 0.06), 0 2px 8px 0 rgba(22, 34, 51, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.05);
  z-index: 200;
`;

export const StyledName = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: ${GlobalColors.text.primary};
`;

export const StyledContainer = styled.div`
  cursor: default;
`;

export const StyledRole = styled.span`
  font-size: 12px;
  font-weight: 400;
  color: ${GlobalColors.text.ghost};
`;
