/**
 * @description
 * Styled base component for Popup and Popover components.
 */
import styled from 'styled-components';
import { GlobalColors } from '../GlobalStyle';

type Props = {
  $width?: number
  $stretched?: boolean
  $radius?: number
  $isVisible?: boolean
}

export const BasePopup = styled.div<Props>`
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  border-radius: ${({ $radius }) => $radius ?? 12}px;
  background: ${GlobalColors.bg.main.secondary};
  box-shadow: 0 12px 24px 0 rgba(22, 34, 51, 0.06), 0 2px 8px 0 rgba(22, 34, 51, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.05);
  z-index: 10000;
  outline: none;

  width: ${({ $width, $stretched = false }) => {
    if ($width) {
      return `${$width}px`;
    }
    return $stretched ? '100%' : 'auto';
  }};
`;
