import { ReactNode } from 'react';
import { FloatingPortal } from '@floating-ui/react';
import { StyledPopupLayout } from './styles';

type Props = {
  trigger: ReactNode
  children: ReactNode
  withPortal: boolean
  isOpen: boolean
  isLazy: boolean
}

export const PopupLayout = ({ children, trigger, withPortal, isLazy, isOpen }: Props) => {
  if (withPortal) {
    return (
      <StyledPopupLayout>
        {trigger}
        <FloatingPortal>
          {isLazy ? isOpen && children : children}
        </FloatingPortal>
      </StyledPopupLayout>
    );
  }

  return (
    <StyledPopupLayout>
      {trigger}
      {isLazy ? isOpen && children : children}
    </StyledPopupLayout>
  );
};
