import type { FC, ReactNode } from 'react';
import { StyleAccordion } from './styles';

interface Props {
 children: ReactNode
}

export const Accordion: FC<Props> = ({ children }) => (
  <StyleAccordion>
    {children}
  </StyleAccordion>
);
