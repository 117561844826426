import { ReactNode } from 'react';
import { BasicSelector } from './BasicSelector';
import { AdvancedSelector } from './AdvancedSelector/AdvancedSelector';
import { Icon } from './list/SelectorItemIcon';

interface Props {
  placeholder?: string
  message?: string
  name?: string
  icon?: Icon
  width?: number
}

export type SelectorOption<T extends string = string> = {
  value: T
  name?: string
  icon?: string
  customIcon?: ReactNode
  disabled?: boolean
  checked?: boolean
}

export interface BasicProps<T extends string = string> extends Props {
  type?: 'basic'
  value?: string
  options: SelectorOption<T>[]
  onChange: (value: T) => void
  disabled?: boolean
}

export interface AdvancedProps<T extends string = string> extends Props {
  type: 'advanced'
  options: SelectorOption<T>[]
  value?: SelectorOption<T>
  onChange: (value: T) => void
  disabled?: boolean
}

export const Selector = <T extends string = string>({
  type = 'basic',
  options,
  width,
  onChange,
  value,
  disabled,
  name,
  message,
  icon,
  ...rest
}: BasicProps<T> | AdvancedProps<T>) => {
  if (type === 'basic' && (typeof value === 'string' || !value)) {
    return (
      <BasicSelector
        options={options}
        onChange={onChange}
        value={value}
        width={width}
        disabled={disabled}
        name={name}
        message={message}
        {...rest}
      />
    );
  }

  if (type === 'advanced' && typeof value !== 'string') {
    return (
      <AdvancedSelector
        icon={icon}
        options={options}
        value={value}
        width={width}
        disabled={disabled}
        onChange={onChange}
        {...rest}
      />
    );
  }

  return null;
};
