import React, {
  FC, FormHTMLAttributes, useMemo, createContext, useState, useContext, useEffect, useCallback, FormEvent,
} from 'react';

type HtmlFormType = Omit<FormHTMLAttributes<HTMLFormElement>, 'onChange'>;

export interface Props extends HtmlFormType {
  children?: React.ReactNode
  onChange?: (value: string) => void
  value?: string
}

type ContextType = {
  value?: string
  onChange:(value: string) => void
}

const RadioGroupContext = createContext<ContextType>({
  value: undefined,
  onChange: (value: string) => {},
});

export const RadioGroup: FC<Props> = ({ children, onChange, value, ...rest }) => {
  const [group, setGroup] = useState(value);

  const handleChange = useCallback((newValue: string) => {
    onChange?.(newValue);
  }, [onChange]);

  const contextValue = useMemo(() => ({
    value: group,
    onChange: handleChange,
  }), [group, handleChange]);

  useEffect(() => {
    setGroup(value);
  }, [value]);

  return (
    <RadioGroupContext.Provider value={contextValue}>
      <form
        {...rest}
      >
        {children}
      </form>
    </RadioGroupContext.Provider>
  );
};

const useRadioGroupContext = () => useContext(RadioGroupContext);

export { RadioGroupContext, useRadioGroupContext };
