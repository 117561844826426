import React, { FC, ReactNode } from 'react';
import { Avatar } from '../Avatar';
import { Container, Info, Wrapper } from './styles';

type Size = 'small' | 'medium' | 'large';

interface Props {
  name?: ReactNode
  phone?: string
  email?: string
  position?: string
  src?: string
  avatarSize?: Size
}

export const Persona: FC<Props> = ({
  name,
  src,
  avatarSize,
  phone,
  email,
  position,
}) => (
  <Container>
    <Avatar src={src} size={16} />
    <Wrapper>
      {name && (
      <Info>
        {name}
      </Info>
      )}
      {position && (
      <Info>
        <span>
          {position}
        </span>
      </Info>
      )}
      {phone && (
      <Info>
        <span>
          {phone}
        </span>
      </Info>
      )}
      {email && (
      <Info>
        <span>
          {email}
        </span>
      </Info>
      )}
    </Wrapper>
  </Container>
);
