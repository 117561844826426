import styled from 'styled-components';
import { LazyImage } from '@quotalogic/fastlane';

export const Picture = styled.picture`
  width: 100%;
  height: 100%;
`;

export const Image = styled(LazyImage)<{ $objectFit: 'cover' | 'contain' }>`
  width: 100%;
  height: 100%;
  object-fit: ${({ $objectFit }) => $objectFit};
  font-size: 12px;
  white-space: nowrap;
`;
