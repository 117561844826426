import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  grid-row: 1/3;
`;
export const Image = styled.img`
  object-fit: contain;
  width: 128px;
  height: 128px;
  border-radius: 4px;

  @media(max-width: 768px) {
    width: 100px;
    height: 100px;
  }
`;

export const SmallImage = styled.img`
  object-fit: cover;
  width: 29px;
  height: 29px;
  border-radius: 4px;

  @media(max-width: 768px) {
    display: none;
  }
`;

export const PictureList = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  gap: 4px;
`;

export const StyledButton = styled.button`
  all: unset;
  width: 29px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media(max-width: 768px) {
    position: absolute;
    height: 12px;
    top: 80px;
    left: 35px;
    background: rgba(255, 255, 255, 0.60);
    border-radius: 8px;
  }
`;
