import styled from 'styled-components';
import { GlobalColors } from '../GlobalStyle';
import { BasePopup } from '../base/Popup';

export const StyledLayout = styled.section`
  position: relative;
    width: 100%;
`;

export const StyledPopup = styled(BasePopup)`
  position: absolute;
  width: 100%;
  z-index: 1;
  margin-top: 4px;
  padding: 0;
  overflow: hidden;
  display: block;
`;

export const StyledInput = styled.input`
  all: unset;
  flex: 1;
  width: 100%;
  padding: 6px 0;
  line-height: 1rem;
`;

export const StyledList = styled.ul`
  max-height: 140px;
  padding: 2px;
  overflow: auto;
`;

export const StyledOption = styled.li`
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }

  &[aria-selected=true] {
    background-color: ${GlobalColors.bg.gray.ghostActive};
    color: inherit;
  }

  &.Mui-focused,
  &.Mui-focusVisible {
    background-color: ${GlobalColors.bg.gray.ghostHover};
    color: inherit;
  }

  &[aria-selected=true].Mui-focused,
  &[aria-selected=true].Mui-focusVisible {
    background-color: ${GlobalColors.bg.gray.ghostActive};
    color: inherit;
  }
`;
