import styled from 'styled-components';

export const StyledPopupLayout = styled.div`
  //align-self: flex-start;
  z-index: 10;
`;

export const StyledTrigger = styled.div`
  position: relative;
`;
