import { FC, ReactNode } from 'react';
import { LogoPic } from '../../LogoPic';
import { Avatar } from '../../Avatar';

export type Icon = 'logo' | 'avatar';

type Props = {
  type: Icon
  src?: string
  text?: string
  custom?: ReactNode
}

export const SelectorItemIcon: FC<Props> = ({ type, src, text, custom }) => {
  if (custom) {
    return custom;
  }

  if (type === 'logo') {
    return (
      <LogoPic isEditable={false} size={24} name={text} src={src} disabled />
    );
  }

  return (
    <Avatar size={24} name={text} src={src} disabled />
  );
};
