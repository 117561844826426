import { FC } from 'react';
import { ChevronsExpandUpRight, ChevronsCollapseUpRight } from '@gravity-ui/icons';
import { Button } from '../../Button';

interface Props {
  onChange: (isCollapsed: boolean) => void;
  isCollapsed: boolean;
}

export const SizeToggle: FC<Props> = ({ onChange, isCollapsed }) => (
  <Button view="ghost" onClick={() => onChange(!isCollapsed)}>
    {isCollapsed ? <ChevronsExpandUpRight /> : <ChevronsCollapseUpRight />}
  </Button>
);
