import { FC, ReactNode } from 'react';
import { DefaultTFuncReturn } from 'i18next';
import {
  Label, SubLabel, ColumnStyle, RowStyle, LabelBlock, ButtonsBlock, LabelRow,
} from './styled';

export interface FormFieldProps {
  /** * should be the same as children name value */
  name: string
  label?: string | DefaultTFuncReturn | ReactNode
  labelPlacement?: 'top' | 'left'
  subLabel?: string | DefaultTFuncReturn
  /** * buttons group */
  buttons?: ReactNode
  /** * input data entry ui element */
  children?: ReactNode
  /** * row label size */
  rowLabelSize?: 'long' | 'short'
}

export const FormField: FC<FormFieldProps> = ({
  name,
  label = undefined,
  labelPlacement = 'top',
  subLabel = undefined,
  buttons = undefined,
  children,
  rowLabelSize,
}) => (
  <>
    {labelPlacement === 'left' && (
    <RowStyle>
      <LabelRow htmlFor={name} $rowLabelSize={rowLabelSize}>{label}
        {subLabel && <SubLabel>({subLabel})</SubLabel>}
      </LabelRow>

      {children}

      {buttons && <ButtonsBlock>{buttons}</ButtonsBlock>}
    </RowStyle>
    )}

    {labelPlacement === 'top' && (
    <ColumnStyle>
      <LabelBlock>
        <Label htmlFor={name}>{label}</Label>

        <ButtonsBlock>
          {subLabel && <SubLabel>({subLabel})</SubLabel>}
          {buttons}
        </ButtonsBlock>
      </LabelBlock>

      {children}
    </ColumnStyle>
    )}
  </>
);
