import { ButtonHTMLAttributes, Children, FC, isValidElement, ReactNode, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import { Wrapper, LoadingStyle } from './styles';
import { darkTheme, blueTheme, yellowTheme, greenTheme, redTheme } from './buttonThemes';
import { ViewType, SizeType, ColorType } from './types';
import { Spinner } from '../Spinner';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  view?: ViewType
  size?: SizeType
  colorTheme?: ColorType
  width?: string
  iconLeft?: ReactNode
  iconRight?: ReactNode
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  loading?: boolean
  rounded?: boolean
  align?: 'left' | 'center' | 'right'
}

export const Button: FC<ButtonProps> = ({
  view,
  size,
  colorTheme = 'dark',
  width,
  type,
  disabled,
  children,
  iconLeft,
  iconRight,
  loading = false,
  rounded = false,
  align = 'center',
  ...rest
}) => {
  /**
   * If the first child is not a valid React element, then we assume that it is text
   */
  const childList = Children.toArray(children);
  const isText = !!childList[0] && !isValidElement(childList[0]);

  const currentTheme = useMemo(() => {
    switch (colorTheme) {
      case 'dark':
        return darkTheme;
      case 'blue':
        return blueTheme;
      case 'yellow':
        return yellowTheme;
      case 'green':
        return greenTheme;
      case 'red':
        return redTheme;
      default:
        return darkTheme;
    }
  }, [colorTheme]);

  const getSpinnerColor = (view: ViewType) => {
    switch (view) {
      case 'primary':
        return currentTheme.primary.primaryText;
      case 'secondary':
        return currentTheme.secondary.secondaryText;
      case 'outline':
        return currentTheme.outline.outlineText;
      case 'ghost':
        return currentTheme.ghost.ghostText;
      default:
        return currentTheme.primary.primaryText;
    }
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <Wrapper
        disabled={disabled || loading}
        type={type ?? 'button'}
        $size={size ?? 'medium'}
        $view={view ?? 'primary'}
        $text={isText}
        $width={width ?? 'auto'}
        $rounded={rounded}
        $loading={loading}
        $align={align}
        {...rest}
      >
        {loading && (
        <LoadingStyle>
          <Spinner size={size === 'large' ? 20 : 16} color={getSpinnerColor(view ?? 'primary')} />
        </LoadingStyle>
        )}
        {iconLeft} {children} {iconRight}
      </Wrapper>
    </ThemeProvider>
  );
};
