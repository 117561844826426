import { styled } from '@mui/system';
import { GlobalColors } from '../GlobalStyle';

export const TriggerBlock = styled('div')`
  display: inline-block;
`;

export const PopupBody = styled('div')`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    background: ${GlobalColors.bg.main.secondary};
    box-shadow: 0px 12px 24px 0px #1622330F, 0px 2px 8px 0px #1622330A;
    padding: 8px 8px 4px 8px;
    margin: 4px 0;
    border-radius: 12px;
    overflow: auto;
    outline: 0;
    z-index: 1000;
    max-height: 80vh;
`;

export const ListStyle = styled('ul')<{ $width: string }>`
  list-style: none;
  user-select: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: ${({ $width }) => $width};
`;

export const ListItem = styled('li')`
  list-style: none;
  user-select: none;
  width: 100%;
`;

export const FooterItem = styled('li')`
  list-style: none;
  user-select: none;
  border-top: 1px solid ${GlobalColors.border.secondary};
  margin-top: 8px;
  padding-top: 8px;
`;
