import styled from 'styled-components';

export const StyledPrimary = styled.h1`
  font-weight: 700;
  font-size: 1.43rem;
  color: inherit;
`;

export const StyledSecondary = styled.h2`
  font-weight: 700;
  font-size: 1rem;
  color: inherit;
`;

export const StyledTertiary = styled.h3`
  font-weight: 700;
  font-size: 0.86rem;
  color: inherit;
`;
