export const Person = ({ width, height, color }: { width: string, height: string, color: string }) => {
  switch (color) {
    case 'danger':
      return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="9.99999" cy="6.66665" rx="3.33333" ry="3.33333" fill="#FF8519" />
          <ellipse opacity="0.5" cx="9.99999" cy="14.1666" rx="5.83333" ry="3.33333" fill="#FF8519" />
        </svg>
      );
    case 'success':
      return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="9.99999" cy="6.66665" rx="3.33333" ry="3.33333" fill="#3CAA3C" />
          <ellipse opacity="0.5" cx="9.99999" cy="14.1666" rx="5.83333" ry="3.33333" fill="#3CAA3C" />
        </svg>
      );
    case 'error':
      return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="9.99999" cy="6.66665" rx="3.33333" ry="3.33333" fill="#EC3F39" />
          <ellipse opacity="0.5" cx="9.99999" cy="14.1666" rx="5.83333" ry="3.33333" fill="#EC3F39" />
        </svg>
      );
    case 'default':
    default:
      return (
        <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <ellipse cx="9.99999" cy="6.66665" rx="3.33333" ry="3.33333" fill="#007FFF" />
          <ellipse opacity="0.5" cx="9.99999" cy="14.1666" rx="5.83333" ry="3.33333" fill="#007FFF" />
        </svg>
      );
  }
};
