import styled, { css } from 'styled-components';
import { GlobalColors } from '../GlobalStyle';
import { Button } from '../Button';

interface ActiveProps {
  $isActive: boolean
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  gap: 24px;

  @media(max-width: 768px) {
      flex-direction: column-reverse;
  }
`;

export const Title = styled.p`
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 24px;

  @media (max-width: 768px) {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 8px;
  }
`;

export const ButtonUpdateFile = styled.label`
  margin: 16px auto 0;
  display: flex;
  color: ${GlobalColors.text.accent};
  padding: 8px 12px;
  gap: 8px;
  position: relative;
  justify-content: center;
  max-width: 100%;
  white-space: nowrap;
  text-decoration: none;
  text-overflow: ellipsis;
  box-sizing: border-box;
  line-height: 1;
  background: transparent;
  cursor: pointer;
  flex-shrink: 0;
`;

export const FileInput = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
`;

export const NavBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 410px;
  width: 48px;
  gap: 10px;
  justify-content: space-between;

  @media(max-width: 768px) {
      display: none;
  }
`;

export const ImageBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 60px;
  gap: 4px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

    &::-webkit-scrollbar {
        width: 4px;
        padding: 0;
        background: #f2f2f2;
    },
    &::-webkit-scrollbar-thumb {
        background-color: rgba(13, 45, 94, 0.08);
        border-radius: 20px;
        border: 3px solid rgba(13, 45, 94, 0.08);
    },

    @media(min-width: 769px) {
        flex-direction: column;
        width: 60px;
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
    
        &::-webkit-scrollbar {
            width: 4px;
            padding: 0;
            background: #f2f2f2;
        },
        &::-webkit-scrollbar-thumb {
            background-color: rgba(13, 45, 94, 0.08);
            border-radius: 20px;
            border: 3px solid rgba(13, 45, 94, 0.08);
      }
    }

    @media(max-width: 768px) {
        flex-direction: row;
        height: 48px;
        overflow-x: auto;
        overflow-y: hidden;
    }
`;

export const Slide = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 410px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  overflow: hidden;
  background: ${GlobalColors.bg.main.thirdly};
  border: 1px solid ${GlobalColors.border.disable};

  @media(max-width: 768px) {
      height: 306px;
  }

  @media(max-width: 524px) {
      height: 270px;
  }

  @media(max-width: 321px) {
      height: 180px;
  }
`;

export const SlideImage = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: 410px;
`;

export const ImageButton = styled.button<ActiveProps>`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  flex-shrink: 0;
  overflow: hidden;
  box-sizing: border-box;
  border: 3px solid #fff;

  &:hover {
    cursor: pointer;
    background: linear-gradient(0deg, rgba(13, 45, 94, 0.16) 0%, rgba(13, 45, 94, 0.16)) 100%;
    & > img {
        opacity: 50%;
    }
  }
  
  ${({ $isActive }) => $isActive && `border: 3px solid ${GlobalColors.icon.accent};`}
`;

export const Image = styled.img`
  object-fit: cover;
  width: 48px;
  height: 48px;
`;

export const EmptyStyle = styled.div`
  display: flex;
  height: 410px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  margin: auto;
  color: ${GlobalColors.text.ghost};
`;

export const DeleteButton = styled(Button)`
  position: absolute;
  bottom: 12px;
  right: 12px;
  color: ${GlobalColors.icon.error};
  background: rgba(255, 255, 255, 0.8);
`;

export const ArrowButton = styled(Button)`
  padding: 4px 12px;
`;

export const SlideButtons = styled.div`
    position: absolute;
    display: flex;
    z-index: 100;
    width: calc(100% - 24px);
    justify-content: space-between;
    top: calc(50% - 16px);

    @media (max-width: 768px) {
        width: calc(100% - 16px);
    }
`;

export const SlideCount = styled.div`
    position: absolute;
    display: inline-flex;
    z-index: 100;
    height: 16px;
    min-width: 16px;
    border-radius: 16px;
    padding: 2px 6px 2px 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    bottom: 8px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    background: rgba(13, 45, 94, 0.8);
    color: white;

    @media (max-width: 768px) {
        bottom: 12px;
    }
`;

export const SlideArrowButton = styled.button`
    all: unset;
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    padding: 0;
    cursor: pointer;
    line-height: 18px;
    border-radius: 8px;
    gap: 6px;
    background-color: rgb(13 45 94 / 16%);
    border: 0;
    color: rgba(13, 45, 94, 0.56);
    
    &:hover {
        background: rgb(13 45 94 / 28%);
    }
    &:disabled {
        pointer-events: none;
        color: rgb(13 45 94 / 28%);
        background: rgb(13 45 94 / 4%);
    }
`;
