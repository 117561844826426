import type { ImgSizeType } from './index';

export const getIconSize = (size: ImgSizeType) => {
  switch (size) {
    case 16:
      return 12;
    case 24:
      return 14;
    case 28:
      return 16;
    case 32:
      return 18;
    case 36:
      return 20;
    case 120:
      return 48;
    case 64:
    default:
      return 32;
  }
};

export const getPicRadius = (size: ImgSizeType) => {
  switch (size) {
    case 16:
      return '2px';
    case 24:
    case 28:
      return '4px';
    case 32:
      return '6px';
    case 36:
      return '8px';
    case 120:
    case 64:
    default:
      return '12px';
  }
};
