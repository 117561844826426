import type { BasicProps } from '../index';
import { SelectorIcon, SelectorStyle, SelectStyle, MessageStyle } from './styles';

export const BasicSelector = <T extends string = string>({
  options,
  onChange,
  value,
  disabled,
  width,
  name,
  message,
  ...rest
}: Omit<BasicProps<T>, 'type'>) => (
  <SelectorStyle $width={width}>
    <SelectStyle
      onChange={(e) => onChange(e.target.value as T)}
      value={value}
      disabled={disabled}
      name={name}
      {...rest}
    >
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      ))}
    </SelectStyle>
    <SelectorIcon $disabled={disabled ?? false} />
    {message && <MessageStyle>{message}</MessageStyle>}
  </SelectorStyle>
);
