import { Xmark } from '@gravity-ui/icons';
import { FC, ReactNode } from 'react';
import { Button } from '../../Button';
import { Layout, Title } from './styles';
import { SizeToggle } from './SizeToggle';

interface Props {
  title?: string | ReactNode
  close: (e?: React.MouseEvent<HTMLElement>) => void
  collapseToggle?: boolean
  isCollapsed: boolean
  toggleCollapse: (isCollapsed: boolean) => void
  controls?: ReactNode
  isScrollable?: boolean
}

export const Header: FC<Props> = ({
  title,
  close,
  collapseToggle,
  isCollapsed,
  toggleCollapse,
  controls,
  isScrollable,
}) => (
  <Layout $isScrollable={isScrollable}>
    <Button view="ghost" onClick={close}>
      <Xmark />
    </Button>
    {collapseToggle && (
      <SizeToggle onChange={toggleCollapse} isCollapsed={isCollapsed} />
    )}
    <Title>{title}</Title>
    {controls}
  </Layout>
);
