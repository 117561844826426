import styled from 'styled-components';
import { ChevronDown } from '@gravity-ui/icons';
import { GlobalColors } from '../../GlobalStyle';

export const SelectorStyle = styled.div<{ $width?: number }>`
    width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
    position: relative;
`;

export const SelectorIcon = styled(ChevronDown)<{ $disabled: boolean }>`
    position: absolute;
    top: 0;
    right: 8px;
    z-index: 0;
    height: 32px;
    width: 12px;
    pointer-events: none;
    color: ${({ $disabled }) => ($disabled
    ? GlobalColors.icon.disable
    : GlobalColors.icon.ghost)};
`;

export const SelectStyle = styled.select`
    all: unset;
    width: 100%;
    cursor: pointer;
    height: 32px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding: 0 8px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid ${GlobalColors.border.primary};
    background: ${GlobalColors.bg.main.secondary};
    
    &:hover {
        border-color: ${GlobalColors.border.primaryHover};
    }
    
    &:focus {
        outline: 2px solid ${GlobalColors.border.focus};
    }
    &:disabled {
       border: 1px solid transparent;
       cursor: default;
       color: ${GlobalColors.text.disable};
       background: ${GlobalColors.bg.darkBlue.disable};
    }
`;

export const MessageStyle = styled.div`
    font-size: 12px;
    line-height: 18px;
    color: ${GlobalColors.text.disable};
`;
