import type { ReactNode, FC } from 'react';
import { StyledPrimary, StyledSecondary, StyledTertiary } from './styles';

type TitleType = 'primary' | 'secondary' | 'tertiary';

interface Props {
  children: ReactNode
  type: TitleType
}

export const Title: FC<Props> = ({ children, type = 'primary' }) => {
  switch (type) {
    case 'secondary':
      return (
        <StyledSecondary>
          {children}
        </StyledSecondary>
      );
    case 'tertiary':
      return (
        <StyledTertiary>
          {children}
        </StyledTertiary>
      );
    case 'primary':
    default:
      return (
        <StyledPrimary>
          {children}
        </StyledPrimary>
      );
  }
};
