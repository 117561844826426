import styled, { css } from 'styled-components';

export const Layout = styled.header<{ $isScrollable?: boolean }>`
  display: flex;
  padding: 16px;
  align-items: center;
  ${({ $isScrollable = false }) => ($isScrollable && css`
    border-bottom: 1px solid #E0E0E0;
  `)}
`;

export const Title = styled.div`
  font-weight: 600;
  flex: 1;
  padding: 0 8px;
  font-size: 16px;
`;
