import styled, { css } from 'styled-components';
import { GlobalColors } from '../GlobalStyle';
import type { ColorStyleType, ImgSizeType } from './index';
import { getPicRadius } from './helpers';

interface SizeProps {
  $size: ImgSizeType
}
interface ColorProps {
  $colorStyle: ColorStyleType
}

export const Wrapper = styled.label<SizeProps>`
  position: relative;
  display: inline-flex;
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  align-items: center;
  justify-content: center;
  background: transparent;
  text-align: center;
  transition: ease-out 0.100ms;
  border-radius: ${({ $size }) => (getPicRadius($size))};
    
    ${({ $size }) => ($size === 64 || $size === 120) && css`
        cursor: pointer;
        &:hover {
            & span {
                display: flex;
            }
        }
        &:focus-visible {
            outline: 2px solid ${GlobalColors.border.focus};
            & span {
                display: flex;
            }
        }
    `}
`;

export const FileInput = styled.input`
  width: 100%;
  visibility: hidden;
  position: absolute;
  opacity: 0;
`;

export const PictureBlock = styled.div<SizeProps>`
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: ${({ $size }) => (getPicRadius($size))};
  //box-shadow: inset 0 0 0 1px ${GlobalColors.border.secondary};
`;

export const FileStyle = styled.div<ColorProps & SizeProps>`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
  border-radius: ${({ $size }) => (getPicRadius($size))};
  align-items: center;
  
  ${({ $colorStyle }) => {
    switch ($colorStyle) {
      case 'success':
        return css`
            background: ${GlobalColors.bg.success.secondary};
            color:  ${GlobalColors.text.success};
          `;
      case 'danger':
        return css`
            background: ${GlobalColors.bg.danger.secondary};
            color:  ${GlobalColors.text.danger};
          `;
      case 'default':
      default:
        return css`
          background: ${GlobalColors.bg.blue.secondary};
          color:  ${GlobalColors.text.accent};
        `;
    }
  }
};
`;

export const TextStyle = styled.span<SizeProps>`
  display: flex;
  font-weight: 600;

  font-size: ${({ $size }) => {
    switch ($size) {
      case 16:
        return 8;
      case 24:
        return 12;
      case 28:
        return 14;
      case 32:
        return 16;
      case 36:
        return 18;
      case 120:
        return 36;
      case 64:
      default:
        return 22;
    }
  }
}px;
`;

export const HoverWrapper = styled.span`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: ${GlobalColors.bg.darkBlue.secondary};
`;

export const HoverIcon = styled.span<{ $bgColor: 'error' | 'default' } & SizeProps>`
  display: none;
  position: absolute;
  top: ${({ $size }) => ($size === 120 ? '6px' : 0)};
  right: ${({ $size }) => ($size === 120 ? '6px' : 0)};
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ $bgColor }) => ($bgColor === 'default'
    ? GlobalColors.bg.blue.primary
    : GlobalColors.bg.error.primary)};
`;

export const CountStyle = styled.div<SizeProps>`
  display: flex;
  position: absolute;
  bottom: -1px;
  right: -1px;
  align-items: center;
  justify-content: center;
  width: ${({ $size }) => (($size === 36 || $size === 32) ? '14px' : '12px')};
  height: ${({ $size }) => (($size === 36 || $size === 32) ? '14px' : '12px')};
  font-size: 10px;
  font-weight: 600;
  border-radius: 50%;
  color: ${GlobalColors.text.primaryOnColor};
  background: ${GlobalColors.bg.success.primary};
`;
