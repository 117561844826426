import { useDropzone } from 'react-dropzone';
import IconUploadArrow from './IconUploadArrow';
import { Container, ContainerInput } from './styles';

export const Dropzone = ({ ...props }) => {
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: { 'image/*': [] } });

  const files = acceptedFiles.map((file: any) => (
    // eslint-disable-next-line no-console
    console.log(file)
  ));

  return (
    <Container>
      <ContainerInput {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
        <IconUploadArrow />
        <input {...getInputProps()} />
        <p>Upload file</p>
      </ContainerInput>
    </Container>
  );
};
