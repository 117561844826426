import React, {
  useRef, ReactNode, InputHTMLAttributes, useCallback, useState, forwardRef, useEffect,
} from 'react';
import { Xmark, ChevronDown, ChevronUp } from '@gravity-ui/icons';
import { DefaultTFuncReturn } from 'i18next';
import {
  Wrapper, InputField, Label, Message, InputLine, ButtonsGroup, ArrowButton, ArrowGroup,
} from './styled';
import { Button } from '../Button';
import { InputSize, getIconSize, StatusType } from './helpers';
import { BaseInput } from '../base/BaseInput';

export interface InputNumProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'type' | 'onBlur'> {
  $size?: InputSize
  label?: string | DefaultTFuncReturn
  width?: string
  name: string
  iconLeft?: ReactNode
  disabled?: boolean
  message?: string
  children?: ReactNode
  onClear?: () => void
  onChange?: (value: number) => void
  onBlur?: (value: number) => void
  readonly?: boolean
  status?: StatusType
  /** * with number-type arrows */
  numeric?: boolean
  /** * positive numbers * 100 */
  isPercents?: boolean
}

const getMinMax = (value: number, min?: string | number, max?: string | number): number => {
  if (min && value < Number(min)) {
    return Number(min);
  }

  if (max && value > Number(max)) {
    return Number(max);
  }

  return value;
};

export const InputNum = forwardRef<HTMLInputElement, InputNumProps>(({
  $size = 'm',
  label = null,
  width,
  name,
  iconLeft,
  disabled = false,
  message,
  onClear,
  onChange,
  status,
  readonly,
  children,
  numeric,
  isPercents = false,
  min,
  max,
  onBlur,
  ...rest
}, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [current, setCurrent] = useState<number>();

  const onChangeNumber = useCallback(() => {
    const value = inputRef.current?.value || 0;
    const resultValue = getMinMax(Math.abs(+value), min, max);
    setCurrent(Number(resultValue));

    if (inputRef.current) {
      // field validation
      inputRef.current.value = String(resultValue);
    }

    if (isPercents) {
      // percents may be only positive numbers
      onChange?.(resultValue * 100);
    } else {
      onChange?.(resultValue);
    }
  }, [isPercents, max, min, onChange]);

  const onChangeHandler = (type: 'up' | 'down') => {
    if (type === 'up') inputRef.current?.stepUp();
    if (type === 'down') inputRef.current?.stepDown();
    onChangeNumber();

    if (inputRef?.current?.value && onBlur) {
      onBlur(Number(inputRef?.current?.value));
    }
  };

  useEffect(() => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(inputRef.current);
      } else {
        ref.current = inputRef.current;
      }
    }
  }, [inputRef.current]);

  return (
    <Wrapper $width={width ?? '100%'}>
      <BaseInput
        $size={$size}
        $status={status}
        $isDisabled={disabled}
        $readOnly={readonly}
      >
        <InputLine>
          {iconLeft}
          {label && (
            <Label
              htmlFor={name}
              $isDisabled={disabled}
              $status={status}
              $readOnly={readonly}
            >
              {label}
            </Label>
          )}

          <InputField
            ref={inputRef}
            name={name}
            type="number"
            onChange={onChangeNumber}
            onBlur={(e) => (onBlur ? onBlur(Number(e.target.value)) : null)}
            disabled={disabled}
            min={min}
            max={max}
            {...rest}
          />

          <ButtonsGroup>
            {numeric && (
              <ArrowGroup $size={$size}>
                <ArrowButton
                  size="small"
                  view="ghost"
                  disabled={current === max}
                  onClick={() => onChangeHandler('up')}
                >
                  <ChevronUp width={getIconSize($size)} height={getIconSize($size)} />
                </ArrowButton>
                <ArrowButton
                  size="small"
                  view="ghost"
                  disabled={current === min}
                  onClick={() => onChangeHandler('down')}
                >
                  <ChevronDown width={getIconSize($size)} height={getIconSize($size)} />
                </ArrowButton>
              </ArrowGroup>
            )}

            {children}

            {onClear
                  && (
                  <Button
                    size={$size === 'xl' ? 'medium' : 'small'}
                    view="ghost"
                    iconLeft={$size === 'xl' ? <Xmark width={16} height={16} /> : <Xmark />}
                    onClick={onClear}
                  />
                  )}
          </ButtonsGroup>
        </InputLine>

      </BaseInput>

      {message && (
        <Message
          $status={status}
          $isDisabled={disabled}
          $readOnly={readonly}
        >
          {message}
        </Message>
      )}
    </Wrapper>
  );
});
