import { FC, ReactNode, useMemo, useState, CSSProperties } from 'react';
import { useTranslation } from 'next-i18next';
import {
  autoUpdate, FloatingPortal, hide, offset, useFloating, useHover, useInteractions,
} from '@floating-ui/react';
import {
  StyledPopup,
  StyledPopupLayout,
  StyledName,
  StyledRole,
  StyledContainer,
} from './styles';

type Props = {
  children: ReactNode
  owner?: {
    id?: string
    name?: string
    image?: string
  }
}

export const AvatarTooltip: FC<Props> = ({ children, owner }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        crossAxis: -12,
      }),
      hide({
        strategy: 'referenceHidden',
        padding: 16,
      }),
    ],
    placement: 'bottom',
  });

  // TODO: maybe we could do better than this
  const styles = useMemo(() => ({
    ...floatingStyles,
    visibility: middlewareData?.hide?.referenceHidden ? 'hidden' : 'visible',
  }) as CSSProperties, [floatingStyles, middlewareData?.hide?.referenceHidden]);

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
  ]);

  return (
    <StyledPopupLayout>
      <StyledContainer ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </StyledContainer>
      {isOpen && (
        <FloatingPortal>
          <StyledPopup
            ref={refs.setFloating}
            style={styles}
            {...getFloatingProps()}
          >
            <StyledName>{owner?.name}</StyledName>
            <StyledRole>{t('delivery.tooltip')}</StyledRole>
          </StyledPopup>
        </FloatingPortal>
      )}
    </StyledPopupLayout>
  );
};
