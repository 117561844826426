import { styled } from '@mui/system';
import { MenuButton } from '@mui/base/MenuButton';
import { MenuItem } from '@mui/base/MenuItem';

export const StyledListbox = styled('ul')`
  z-index: 1;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.07);
  min-width: 120px;
  list-style-type: none;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
`;

export const StyledButton = styled(MenuButton)`
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
    
  &:hover {
    background: #e5e5e5;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  padding: 4px 8px;
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
    
  &:hover {
    background: #F5F5F5;
  }
    
  &:first-of-type {
    margin-top: 4px;
  }
    
  &:last-of-type {
    margin-bottom: 4px;
  }
`;
