import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  
  @media(max-width: 992px) {
    flex-direction: column-reverse;
  }
`;

export const Aside = styled.aside`
  position: relative;
  width: 78px;
  overflow: auto;
  padding: 0 8px;

  //&::-webkit-scrollbar {
  //  width: 4px;
  //  padding: 0;
  //  background: #f2f2f2;
  //},
  //
  //&::-webkit-scrollbar-thumb {
  //  background-color: blue;
  //  border-radius: 20px;
  //  border: 3px solid #8A80FF;
  //},

  @media(max-width: 992px) {
    height: 78px;
    width: 100%;
    padding: 4px 0;
  }
`;

export const AsideArrow = styled.button`
  display: none;

  @media(max-width: 992px) {
    all: unset;
    display: flex;
    position: absolute;
    width: 48px;
    height: 52px;
    align-items: center;
    z-index: 100;
  }
`;

export const AsideLeftArrow = styled(AsideArrow)`
  left: 24px;
  bottom: 46px;
  background: linear-gradient(90deg, #FFF 10%, rgba(255, 255, 255, 0.00) 100%);
  padding-left: 12px;
`;

export const AsideRightArrow = styled(AsideArrow)`
  right: 24px;
  bottom: 46px;
  justify-content: flex-end;
  background: linear-gradient(-90deg, #FFF 10%, rgba(255, 255, 255, 0.00) 100%);
  padding-right: 12px;
`;

export const StyledList = styled.div`
  list-style: none;
  padding: 4px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  align-items: center;

  @media(max-width: 992px) {
    flex-direction: row;
  }
`;

export const StyledItem = styled.button<{ $active: boolean }>`
  all: unset;
  width: 48px;
  height: 48px;
  border-radius: 4px;
  ${({ $active }) => ($active && 'outline: 2px solid #8A80FF')};
`;

export const StyledItemImage = styled.img`
  object-fit: cover;
  width: 48px;
  height: 48px;
  border-radius: 4px;
`;

export const StyledImageContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 8px;
`;

export const Image = styled.img`
  object-fit: contain;
  width: 100%;
  max-width: 586px;
  max-height: 428px;
  border-radius: 4px;
  align-self: center;

  @media(max-width: 992px) {
    max-width: none;
  }
`;

export const StyledButton = styled.button`
  all: unset;
  display: flex;
  width: 34px;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  
  &:hover {
    background: #E5E4FB;
  }

  @media(max-width: 992px) {
    position: absolute;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.60);
  }
`;

export const StyledLeftButton = styled(StyledButton)`
  @media(max-width: 992px) {
    left: 12px;
  }
`;

export const StyledRightButton = styled(StyledButton)`
  @media(max-width: 992px) {
    left: auto;
    right: 12px;
  }
`;
