import React, { InputHTMLAttributes } from 'react';
import { DefaultTFuncReturn } from 'i18next';
import { Label, CheckboxStyle } from './styled';
import { SizeType, StatusType, ColorType } from './types';

export interface CheckboxProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'color'> {
    size?: SizeType
    label?: string | DefaultTFuncReturn
    /** * unchecked error prop */
    status?: StatusType
    /** * change checked color */
    color?: ColorType,
}

export const Checkbox: React.FC<CheckboxProps> = ({
  disabled = false,
  name,
  size = 'lxl',
  label = null,
  status = 'default',
  color = 'default',
  ...rest
}) =>
  (
    <Label
      $disabled={disabled}
    >
      <CheckboxStyle
        type="checkbox"
        name={name}
        $status={status}
        $size={size}
        $color={color}
        disabled={disabled}
        {...rest}
      />
      {label}
    </Label>
  );
