/**
 * @description
 * Popover component that can be used to display a floating element relative to a trigger element.
 */
import {
  Children, CSSProperties, FC, isValidElement, ReactNode, useMemo, useState,
} from 'react';
import {
  autoUpdate, flip, FloatingFocusManager,
  FloatingPortal,
  hide, offset,
  Placement,
  useFloating, useHover,
  useInteractions,
} from '@floating-ui/react';
import { StyledTrigger } from './styles';
import { BasePopup } from '../base';

type Props = {
  content: ReactNode | string
  children: ReactNode
  width?: number
  placement?: Placement
  allowedPlacements?: Placement[]
  offsetX?: number
  offsetY?: number
  hideOffset?: number
  radius?: number
}

export const Tooltip: FC<Props> = ({
  children,
  content,
  width,
  placement = 'top',
  allowedPlacements = ['top', 'bottom', 'top-start', 'top-end', 'bottom-start', 'bottom-end'],
  offsetX = 0,
  offsetY = 0,
  hideOffset = 0,
  radius = 8,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        crossAxis: offsetX,
        mainAxis: offsetY,
      }),
      hide({
        strategy: 'referenceHidden',
        padding: hideOffset,
      }),
      flip({
        fallbackPlacements: allowedPlacements,
      }),
    ],
    placement,
  });

  const contentList = Children.toArray(content);
  const isText = !!contentList[0] && !isValidElement(contentList[0]);

  // TODO: maybe we could do better than this
  const styles = useMemo(() => ({
    ...floatingStyles,
    visibility: middlewareData?.hide?.referenceHidden ? 'hidden' : 'visible',
    padding: isText ? '4px 8px' : '4px',
  }) as CSSProperties, [floatingStyles, middlewareData?.hide?.referenceHidden]);

  const hover = useHover(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
  ]);

  return (
    <>
      <StyledTrigger
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {children}
      </StyledTrigger>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal initialFocus={refs.floating}>
            <BasePopup
              $isVisible
              ref={refs.setFloating}
              style={styles}
              {...getFloatingProps()}
              $width={width}
              $radius={radius}
            >
              {content}
            </BasePopup>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};
