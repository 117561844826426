import { FC, ReactNode, useCallback } from 'react';
import { Modal } from '../Modal';
import { Button } from '../Button';

type Props = {
  title: string
  isVisible: boolean
  onClose: () => void
  onSubmit?: () => void
  submitText: string
  cancelText: string
  children?: ReactNode
  isLoading?: boolean
  isDisabled?: boolean
}

export const Alert: FC<Props> = ({
  title,
  isVisible,
  onClose,
  onSubmit,
  children,
  submitText,
  cancelText,
  isLoading,
  isDisabled,
}) => {
  const handleSubmit = useCallback(() => {
    onSubmit && onSubmit();
  }, [onSubmit]);

  return (
    <Modal
      size="small"
      isVisible={isVisible}
      onClose={onClose}
      title={title}
      footer={(
        <>
          <Button
            onClick={handleSubmit}
            loading={isLoading}
            disabled={isDisabled}
          >
            {submitText}
          </Button>
          <Button view="ghost" colorTheme="red" onClick={onClose}>
            {cancelText}
          </Button>
        </>
      )}
    >
      {children}
    </Modal>
  );
};
