import { GlobalColors } from '../GlobalStyle';

export const defaultTheme = {
  color: {
    default: GlobalColors.text.primary,
    active: GlobalColors.text.primaryOnColor,
  },
  background: {
    default: 'transparent',
    active: '#353535',
  },
  hover: {
    background: {
      default: GlobalColors.bg.gray.ghostHover,
      active: '#353535',
    },
  },
};

export const blueTheme = {
  color: {
    default: GlobalColors.text.accent,
    active: GlobalColors.text.accent,
  },
  background: {
    default: 'transparent',
    active: GlobalColors.bg.blue.ghostHover,
  },
  hover: {
    background: {
      default: GlobalColors.bg.blue.ghostHover,
      active: GlobalColors.bg.blue.ghostHover,
    },
  },
};
