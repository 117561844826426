import { FC } from 'react';
import { Theme } from './types';
import { StyledSpinner } from './styles';

interface Props {
  size: number
  theme?: Theme
  color?: string
}

export const Spinner: FC<Props> = ({
  size = 48,
  theme = 'light',
  color,
}) => <StyledSpinner $size={size} $theme={theme} $color={color} />;
