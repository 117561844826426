import React, { ReactNode, useCallback, useState, MouseEvent, KeyboardEvent } from 'react';
import { Unstable_Popup as BasePopup, PopupPlacement } from '@mui/base/Unstable_Popup';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { PopupBody, FooterItem, ListStyle, TriggerBlock, ListItem } from './styles';

type PopoverOption = unknown | any;

export interface PopoverProps<T extends PopoverOption> {
  width?: string
  // eslint-disable-next-line max-len
  /** * top-start | top | top-end | left-start | left | left-end | right-start | right | right-end | bottom-start | bottom | bottom-end */
  placement?: PopupPlacement
  /** * open trigger element */
  children: ReactNode
  header?: ReactNode
  footer?: ReactNode
  dropList?: Array<T>
  item?: (args: T) => ReactNode
  open?: boolean
}

export const PopoverMenu = <T extends PopoverOption = unknown>({
  width,
  placement = 'top',
  children,
  header,
  footer,
  dropList,
  item,
  open = false,
}: PopoverProps<T>) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [isOpen, setIsOpen] = useState(open);

  const handleClick = useCallback((e: MouseEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div>
        <TriggerBlock
          ref={setAnchor}
          aria-describedby="popper"
          role="button"
          onClick={handleClick}
          tabIndex={0}
          onKeyDown={handleClick}
        >
          {children}
        </TriggerBlock>

        <BasePopup
          id="popper"
          open={isOpen}
          anchor={anchor}
          placement={placement}
          offset={4}
          strategy="absolute"
         // disablePortal
        >
          <PopupBody>
            <ListStyle $width={width ?? 'auto'}>
              {header && <ListItem> {header}</ListItem>}

              {item && (
                dropList && dropList.map((itemData, index) => (
                  <ListItem key={`${index}_MenuItem`}>
                    {item(itemData)}
                  </ListItem>
                )))}

              {footer && <FooterItem>{footer}</FooterItem>}
            </ListStyle>
          </PopupBody>
        </BasePopup>
      </div>
    </ClickAwayListener>
  );
};
