import styled, { css } from 'styled-components';

type Size = 'small' | 'medium'
type Color = 'light' | 'dark'

interface SizeProps {
  $size: Size
}

interface ActiveProps {
  $isActive: boolean
}

interface ColorProps {
  $color: Color
}

export const SwitchWrapper = styled.label<SizeProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  
  ${({ $size }) => (
    $size === 'small'
      ? 'height: 24px; width: 46px;'
      : 'height: 32px; width: 62px;')};
 
  & > input {
    display:none;
  }
`;

export const Slider = styled.span<SizeProps & ColorProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ $color }) => (
    $color === 'light'
      ? '#EBEEF2'
      : '#3D4C5E')};
  border-radius: 8px;
  -webkit-transition: .4s;
  transition: .4s;

  &:before {
    box-sizing: border-box;
    position: absolute;
    content: "";
    left: 2px;
    bottom: 2px;
    border-radius: 6px;
    border: 1px solid white;
    background-color: white;
    transition: .4s;
    
    ${({ $size }) => (
    $size === 'small'
      ? 'height: 20px; width: 20px;'
      : 'height: 28px; width: 28px;')};
  }
`;

export const SwitchInput = styled.input<SizeProps>`
  all: unset;
  &:checked + ${Slider} {
   // background-color: #2196F3;
  }
  &:focus + ${Slider} {
    border: 2px solid #99CAFE
  }
  &:checked + ${Slider}:before {
    ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
            -webkit-transform: translateX(22px);
            -ms-transform: translateX(22px);
            transform: translateX(22px);
          `;
      case 'medium':
      default:
        return css`
            -webkit-transform: translateX(30px);
            -ms-transform: translateX(30px);
            transform: translateX(30px);`;
    }
  }}
  }
`;

export const Icon = styled.span<ActiveProps & SizeProps & ColorProps>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 28px;
  border-radius: 6px;
  transition-property: color;
  transition: .4s;
  ${({ $size }) => (
    $size === 'small'
      ? 'height: 20px; width: 20px;'
      : 'height: 28px; width: 28px;')};
  
  ${({ $color, $isActive }) => {
    switch ($color) {
      case 'dark':
        return css`
          color: ${!$isActive ? '#fff' : '#3D4C5E'}`;
      case 'light':
      default:
        return css`
          color: #3D4C5E;`;
    }
  }}
`;
