import { Check } from '@gravity-ui/icons';
import type { ReactNode } from 'react';
import { Icon, SelectorItemIcon } from './SelectorItemIcon';
import { StyledItem, StyledText } from './styles';

export type Item<T = string> = {
  id: T
  icon?: string
  customIcon?: ReactNode
  text?: string
  isSelected?: boolean
  disabled?: boolean
}

export type ItemProps<T> = {
  item: Item<T>
  onClick?: (id: T) => void
  icon?: Icon
}

export const SelectorItem = <T extends string>({ item, onClick, icon }: ItemProps<T>) => (
  <StyledItem
    role="option"
    onClick={() => onClick?.(item.id)}
    onKeyDown={() => onClick?.(item.id)}
    $disabled={item.disabled}
    $isSelected={item.isSelected}
    aria-selected={item.isSelected}
  >
    {icon && <SelectorItemIcon type={icon} src={item?.icon} custom={item?.customIcon} text={item?.text} />}
    <StyledText>
      {item.text}
    </StyledText>
    {(item.isSelected) && <Check />}
  </StyledItem>
);
