import styled, { css } from 'styled-components';
import { ViewType, SizeType } from './types';
import { GlobalColors } from '../GlobalStyle';

interface Props {
  $size: SizeType
  $view: ViewType
  $text: boolean
  $width: string
  $rounded: boolean
  $loading: boolean
  $align: 'left' | 'center' | 'right'
}

const getThemeStyles = (view: ViewType) => {
  switch (view) {
    case 'primary':
      return css`
        ${({ theme }) => css`
          color: ${theme.primary.primaryText};
          background-color: ${theme.primary.primaryDefault};
          border: 0;
          
          &:active {
            background-color: ${theme.primary.primaryActive};
          }
          &:hover {
            background-color: ${theme.primary.primaryHover};
            border: 0;
          }`
}; `;
    case 'secondary':
      return css`
        ${({ theme }) => css`
          color: ${theme.secondary.secondaryText};
          background-color: ${theme.secondary.secondaryDefault};
          border: 0;

          &:active {
            background-color: ${theme.secondary.secondaryActive};
          }
          &:hover {
            background-color: ${theme.secondary.secondaryHover};
            border: 0;
          }`
}; `;
    case 'outline':
      return css`
        ${({ theme }) => css`
          color: ${theme.outline.outlineText};
          background-color: ${theme.outline.outlineDefault};
          border: 1px solid ${theme.border};

          &:active {
            background-color: ${theme.outline.outlineActive};
          }
          &:hover {
            background-color: ${theme.outline.outlineHover};
            border: 1px solid transparent;
          }`
}; `;
    case 'ghost':
      return css`
        ${({ theme }) => css`
          border: 0;
          color: ${theme.ghost.ghostText};
          background-color: ${theme.ghost.ghostDefault};

          &:active {
            background-color: ${theme.ghost.ghostActive};
          }
          &:hover {
            background-color: ${theme.ghost.ghostHover};
          }`
}; `;
    case 'link':
      return css`
        ${({ theme }) => css`
          color: ${theme.link.linkText};
          font-size: 0.857rem;
          font-weight: 500;
          line-height: 16px;
          border: 0;
          cursor: pointer;
        `
}; `;
    default:
      return css`
        ${({ theme }) => css`
          color: ${theme.primary.primaryText};
          background-color: ${theme.primary.primaryDefault};
          border: 0; 
          
          &:hover {
            background-color: ${theme.primary.primaryHover};
            border: 0;
          }`
}; `;
  }
};

const getSizeStyle = (size: SizeType, withText: boolean) => {
  switch (size) {
    case 'small':
      return css`
        font-size: 12px;
        line-height: 16px;
        border-radius: 6px;
        gap: 4px;
        padding: 4px ${withText ? '8px' : '4px'};
      `;
    case 'large':
      return css`
        font-size: 16px;
        line-height: 20px;
        border-radius: 10px;
        gap: 8px;
        padding: 10px 12px;
      `;
    case 'medium':
    default:
      return css`
        font-size: 14px;
        line-height: 18px;
        border-radius: 8px;
        gap: 6px;
        padding: 8px ${withText ? '12px' : '8px'};
      `;
  }
};

const getAlignStyle = (align: 'left' | 'center' | 'right') => {
  switch (align) {
    case 'left':
      return css`
        justify-content: flex-start;
        text-align: left;
      `;

    case 'right':
      return css`
        justify-content: flex-end;
        text-align: right;
      `;

    case 'center':
    default:
      return css`
        justify-content: center;
        text-align: center;
      `;
  }
};

export const Wrapper = styled.button<Props>`
  position: relative;
  all: unset;
  width: ${({ $width }) => $width};
  appearance: none;
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  max-width: 100%;
  font-weight: 600;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
  transition: ease-out 0.100ms;
    
  ${({ $align }) => getAlignStyle($align)}
  ${({ $size, $text }) => getSizeStyle($size, $text)}
  ${({ $view }) => getThemeStyles($view)}

  ${({ $rounded }) => $rounded && css`border-radius: 100px;`}
  
  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.focus};
  }
  
  &:disabled {
    pointer-events: none;
    ${({ $view, $loading }) => {
    switch ($view) {
      case 'outline':
        return css`
          color: ${$loading ? 'transparent' : GlobalColors.text.disable};
          ${!$loading && 'background: transparent'};
          ${!$loading && `border: 1px solid  ${GlobalColors.border.disable}`};
        `;
      case 'ghost':
        return css`
          color:  ${$loading ? 'transparent' : GlobalColors.text.disable};
          ${!$loading && 'background: transparent'};
          ${!$loading && 'border: none'};
        `;
      case 'link':
        return css`
          ${!$loading && 'background: none'};
          ${!$loading && 'cursor: not-allowed'};
          color:  ${$loading ? 'transparent' : GlobalColors.text.disable};
        `;
      case 'primary':
      case 'secondary':
      default:
        return css`
          color:${$loading ? 'transparent' : GlobalColors.icon.disable};
          ${!$loading && `background: ${GlobalColors.bg.darkBlue.disable}`};
        `;
    }
  }};
  }
  
  svg {
    ${({ $loading }) => $loading && css`color: transparent`}
  }
`;

export const LoadingStyle = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${GlobalColors.icon.disable};
`;
