export type InputSize =
    // 's'
     'm'
    | 'l'
    | 'xl';

export type StatusType =
    'default'
    | 'error'
    | 'success';

export const getIconSize = (size: InputSize) => {
  switch (size) {
    // case 's':
    //   return 8;
    case 'm':
      return 12;
    case 'l':
    case 'xl':
      return 16;
    default:
      return 12;
  }
};
