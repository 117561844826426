import styled from 'styled-components';
import { GlobalColors } from '../../GlobalStyle';

export const Wrapper = styled.label<{ $width: string, $isSelected: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: ${({ $width }) => $width};
  height: ${({ $width }) => $width};
  padding: 12px;
  border-radius: 12px;
  border: 1px dashed ${({ $isSelected }) => ($isSelected
    ? 'transparent'
    : GlobalColors.border.primary)}; 
  gap: 8px;
  z-index: 3;
  background: ${GlobalColors.bg.main.thirdly};
    
  &:hover {
    border: 1px dashed ${GlobalColors.border.focus};
    cursor: pointer;
  }
`;

export const IconStyle = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  color: ${GlobalColors.text.accent};
`;

export const ErrorMassage = styled.div<{ $width: string }>`
  width: ${({ $width }) => $width};
  color: ${GlobalColors.text.error};
  margin-top: 2px;
  font-size: 12px;
  line-height: 14px;
`;

export const TextStyle = styled.div`
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    & > span {
        color: ${GlobalColors.text.accent};
        margin-left: 4px;
    }
`;

export const FileInput = styled.input`
  opacity: 1;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const SubText = styled.span<{ $isDisabled: boolean }>`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;
