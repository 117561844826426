import { FC, HTMLAttributes, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import type { LinkSize, LinkType } from './types';
import { ButtonAlike } from './styles';
import { blueTheme, defaultTheme } from './themes';

type Theme = 'default' | 'blue'

interface Props extends HTMLAttributes<HTMLAnchorElement>{
  view?: LinkType
  size?: LinkSize
  active?: boolean
  href: string
  target?: string
  theme?: Theme
}

export const Link: FC<Props> = ({
  view,
  size = 'medium',
  children,
  active = false,
  theme = 'default',
  ...props
}) => {
  const currentTheme = useMemo(() => {
    switch (theme) {
      case 'blue':
        return blueTheme;

      case 'default':
      default:
        return defaultTheme;
    }
  }, [theme]);

  if (view === 'button') {
    return (
      <ThemeProvider theme={currentTheme}>
        <ButtonAlike $size={size} $active={active} {...props}>
          {children}
        </ButtonAlike>
      </ThemeProvider>
    );
  }

  return null;
};
