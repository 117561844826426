import { ReactNode, useState } from 'react';
import { Wrapper } from './styles';
import { SearchInput } from './SearchInput';
import { SearchButton } from './SearchButton';
import { ResultList } from './ResultList';

interface Props<T> {
  isOpenDefault?: boolean
  searchResult?: Array<T>
  onSearch: (text: string) => void;
  resultElement?: (item: T) => ReactNode
  onClear?: () => void
}

export const SearchBar = <T extends Record<'id', string>>({
  isOpenDefault = false,
  searchResult,
  onSearch,
  resultElement,
  onClear,
}: Props<T>) => {
  const [isOpen, setIsOpen] = useState(isOpenDefault);

  const handleOpen = (status: boolean) => {
    setIsOpen(status);
    if (status === false && onClear) {
      onClear();
    }
  };

  if (isOpen) {
    return (
      <Wrapper $isOpen={isOpen}>
        <SearchInput setIsOpen={handleOpen} onSearch={onSearch} />
        {searchResult && searchResult.length > 0 && (
          <ResultList<T> searchResult={searchResult} setIsOpen={handleOpen} resultElement={resultElement} />
        )}
      </Wrapper>
    );
  }

  return (
    <SearchButton setIsOpen={setIsOpen} />
  );
};
