import styled, { css } from 'styled-components';
import { LinkSize } from './types';

export const ButtonAlike = styled.a<{ $size: LinkSize, $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
    
  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          font-size: 12px;
          line-height: 16px;
          border-radius: 6px;
          gap: 4px;
          padding: 4px;
        `;

      case 'large':
        return css`
          font-size: 16px;
          line-height: 20px;
          border-radius: 10px;
          gap: 8px;
          padding: 10px 12px;
        `;

      case 'medium':
      default:
        return css`
          font-size: 14px;
          line-height: 18px;
          border-radius: 8px;
          gap: 6px;
          padding: 8px;
        `;
    }
  }}

  color: ${({ $active, theme }) => ($active ? theme.color.active : theme.color.default)};
  background: ${({ $active, theme }) => ($active ? theme.background.active : theme.background.default)};
  
  &:hover {
    background: ${({ $active, theme }) => ($active ? theme.hover.background.active : theme.hover.background.default)};
  }
`;
