import React, { FC, useRef, ReactNode, InputHTMLAttributes, useCallback } from 'react';
import { Xmark } from '@gravity-ui/icons';
import { DefaultTFuncReturn } from 'i18next';
import { Button } from '../Button';
import { InputSize, StatusType } from './helpers';
import {
  Wrapper, InputField, Label, Message, InputLine, ButtonsGroup,
} from './styled';
import { BaseInput } from '../base/BaseInput';

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
    $size?: InputSize
    name: string
    label?: string | DefaultTFuncReturn
    width?: string
    iconLeft?: ReactNode
    iconRight?: ReactNode
    disabled?: boolean
    type?: 'hidden' | 'password' | 'text' | 'email' | 'tel' | 'url' | 'card' | 'money'
    message?: string
    children?: ReactNode
    onClear?: () => void
    readonly?: boolean
    status?: StatusType
    onChange?: (value: string) => void
}

export const Input: FC<InputProps> = ({
  $size = 'm',
  label = null,
  name,
  iconLeft,
  iconRight,
  disabled = false,
  message,
  onClear,
  status = 'default',
  width,
  type = 'text',
  readonly,
  onChange,
  children,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(() => {
    onChange?.(inputRef.current?.value ?? '');
  }, [onChange]);

  return (
    <Wrapper $width={width ?? '100%'}>
      <BaseInput
        $size={$size}
        $isDisabled={disabled}
        $status={status}
        $readOnly={readonly}
      >
        <InputLine>
          {iconLeft}
          {label && (
          <Label
            htmlFor={name}
            $isDisabled={disabled}
            $status={status}
            $readOnly={readonly}
          >
            {label}
          </Label>
          )}

          <InputField
            ref={inputRef}
            name={name}
            type={type}
            onChange={handleChange}
            disabled={disabled}
            {...rest}
          />

          <ButtonsGroup>
            {children}

            {onClear && (
              <Button
                size={$size === 'xl' ? 'medium' : 'small'}
                view="ghost"
                iconLeft={$size === 'xl' ? <Xmark width={16} height={16} /> : <Xmark />}
                onClick={onClear}
              />
            )}

            {iconRight}

          </ButtonsGroup>
        </InputLine>

      </BaseInput>

      {message && (
        <Message
          $status={status}
          $isDisabled={disabled}
          $readOnly={readonly}
          data-testid={`${name}-message`}
        >
            {message}
        </Message>
      )}
    </Wrapper>
  );
};
