import { createNanoEvents } from 'nanoevents';

interface Events {
  load: (src: string) => void,
}

type FastlaneEventName = 'load'

type FastlaneEventPayload = {
  id: string
}

export const emitter = createNanoEvents<Events>();

export const emitFastlane = (eventName: FastlaneEventName, payload: FastlaneEventPayload) => {
  if (payload.id && eventName === 'load') {
    const folder = payload.id.substring(0, 2);
    const src = `/media/${folder}/${payload.id}`;
    emitter.emit(eventName, src);
  }
};
