import styled, { css } from 'styled-components';
import { GlobalColors } from '../GlobalStyle';

interface LabelProps {
  $rowLabelSize?: 'long' | 'short'
}

export const LabelBlock = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const ButtonsBlock = styled.div`
    display: flex;
    align-items: center;
    margin-left: 8px;
    gap: 2px;
`;

const labelStyles = css`
    display: block;
    box-sizing: content-box;
    font-weight: 600;
    flex-shrink: 0;
    font-size: 14px;
    padding-right: 16px;
    line-height: 16px;
    color: ${GlobalColors.text.primary};
`;

export const Label = styled.label<LabelProps>`
    ${labelStyles};
`;

export const LabelRow = styled.label<LabelProps>`
    width: ${({ $rowLabelSize }) => ($rowLabelSize === 'short' ? '80px' : '140px')};
    ${labelStyles};
    height: 32px;
    display: flex;
    align-items: center;
`;

export const SubLabel = styled.span`
    font-weight: 400;
    line-height: 16px;
    font-size: 12px;
    margin-left: 4px;
    white-space: nowrap;
    color: ${GlobalColors.text.ghost};
`;

export const ColumnStyle = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: fit-content;
    gap: 6px;
`;

export const RowStyle = styled.div`
    display: flex;
    align-items: flex-start;
`;
