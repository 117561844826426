import type { ImgSizeType } from './index';

export const getPersonSize = (size: ImgSizeType) => {
  switch (size) {
    case 16:
      return '12';
    case 24:
      return '16';
    case 28:
      return '18';
    case 32:
      return '20';
    case 36:
      return '22';
    case 120:
      return '64';
    case 64:
    default:
      return '36';
  }
};

export const getIconSize = (size: ImgSizeType) => {
  switch (size) {
    case 16:
      return 12;
    case 24:
      return 14;
    case 28:
      return 16;
    case 32:
      return 18;
    case 36:
      return 20;
    case 120:
      return 48;
    case 64:
    default:
      return 32;
  }
};

export const getPicRadius = (size: ImgSizeType) => {
  switch (size) {
    case 16:
      return '2px';
    case 24:
    case 28:
      return '4px';
    case 32:
      return '6px';
    case 36:
      return '8px';
    case 120:
    case 64:
    default:
      return '12px';
  }
};

export const getShortName = (name?: string) => {
  if (!name) return '';

  const arr = name.split(' ');
  let result = arr[0][0]?.toUpperCase();
  if (arr.length > 1 && typeof arr[1][0] === 'string') {
    result += arr[1][0].toUpperCase();
  }

  return result;
};
