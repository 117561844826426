import { useId } from 'react';
import { useAutocomplete } from '@mui/base';
import { ChevronsExpandVertical, Check } from '@gravity-ui/icons';
import { StyledInput, StyledLayout, StyledList, StyledOption, StyledPopup } from './styles';
import { BaseInput } from '../base/BaseInput';

type AutocompleteOption = {
  label: string
  value: string
} | string;

interface Props<T extends AutocompleteOption> {
  list: T[]
  value: T | null
  onChange: (value: T | null) => void
  maxHeight?: number
}

export const Autocomplete = <T extends AutocompleteOption = string>({ list, value, onChange, maxHeight }: Props<T>) => {
  const id = useId();

  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id,
    options: list,
    getOptionLabel: (option) => (typeof option === 'string' ? option : option.label),
    isOptionEqualToValue: (option, value) => (
      typeof option === 'object' && typeof value === 'object'
        ? option.value === value.value
        : option === value
    ),
    value,
    onChange: (event, newValue) => onChange(newValue),
  });

  return (
    <StyledLayout>
      <BaseInput {...getRootProps()}>
        <StyledInput {...getInputProps()} />
        <ChevronsExpandVertical height={12} />
      </BaseInput>
      {groupedOptions.length > 0 && (
        <StyledPopup>
          <StyledList {...getListboxProps()}>
            {(groupedOptions as T[]).map((option, index) => (
              <StyledOption {...getOptionProps({ option, index })}>
                {typeof option === 'object' ? option.label : option}
                {value === option && (
                  <Check />
                )}
              </StyledOption>
            ))}
          </StyledList>
        </StyledPopup>
      )}
    </StyledLayout>
  );
};
