import { useCallback } from 'react';
import { Item, SelectorItem } from './SelectorItem';
import { LoaderWrapper, StyledList } from './styles';
import { Icon } from './SelectorItemIcon';
import { Spinner } from '../../Spinner';

type Props<T> = {
  list: Item<T>[]
  onSelect?: (id: T) => void
  isLoading?: boolean
  icon?: Icon
}

export const SelectorList = <T extends string = string>({
  list,
  onSelect,
  icon,
  isLoading = false,
}: Props<T>) => {
  const handleClick = useCallback((id: T) => {
    onSelect?.(id);
  }, [onSelect]);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Spinner size={24} />
      </LoaderWrapper>
    );
  }

  return (
    <StyledList>
      {list.map((item) => (
        <SelectorItem<T> key={item.id} item={item} onClick={handleClick} icon={icon} />
      ))}
    </StyledList>
  );
};
