import { FC } from 'react';
import { Avatar, ImgSizeType } from '../Avatar';
import { AvatarTooltip } from './AvatarTooltip';

type Props = {
  owner?: {
    id?: string
    name?: string
    image?: string
  }
  size?: ImgSizeType
  src?: string
  shortText?: string
}

export const AvatarHint: FC<Props> = ({
  owner,
  shortText,
  src,
  size,
}) => (
  <AvatarTooltip owner={owner}>
    <Avatar size={size} src={src} name={shortText} alt={shortText} />
  </AvatarTooltip>
);
