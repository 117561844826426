import { FC } from 'react';
import { StyleList } from './styles';

interface Props {
  children: any
}

export const List: FC<Props> = ({ children }) => (
  <StyleList>
    { children }
  </StyleList>
);
