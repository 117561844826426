import styled, { css } from 'styled-components';

interface ActiveProps {
  $isActive: boolean
}
interface DisabledProps {
  $isDisabled: boolean
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ImageBlock = styled.div`
  width: auto;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  max-width: 700px;
  text-align: center;
  height: 465px;
`;

export const Slides = styled.div`
  display: flex;
  overflow-x: scroll;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
`;

export const Slide = styled.div`
  display: flex;
  width: 695px;
  height: 465px;
  max-width: 100%;
  margin-bottom: 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  transform-origin: center center;
  transform: scale(1);
  scroll-snap-align: center;
  
  & > img {
    object-fit: contain;
    width: auto;
    height: auto;
    max-height: 400px;
  }
`;

export const NavBlock = styled.div`
  display: flex;
  width: 100%;
  max-width: 844px;
  justify-content: space-between;
  position: absolute;
  top: 40%;
`;

export const disablesStyle = css`
  cursor: default;
  & > svg {
    color: #828286
  };
  &:hover {
    background-color:  #fff;
  }
`;

export const ArrowButton = styled.button<DisabledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  & > svg {
    color: #8A80FF
  };
  &:hover {
    background-color:  #E5E4FB;
  };
  ${({ $isDisabled }) => ($isDisabled && disablesStyle)};
`;

export const ImageList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 695px;
  margin: 8px auto 0;
  gap: 8px;
  overflow: hidden;
`;

export const ImageButton = styled.button<ActiveProps>`
  width: 48px;
  height: 48px;
  overflow: hidden;
  border-radius: 4px;
  
  &:hover {
    cursor: pointer;
    transition: all 0.1s ease-out;
    border: 2px solid #8A80FF
  }
  
  ${({ $isActive }) => $isActive && 'border: 2px solid #8A80FF;'}
`;
