import styled, { css } from 'styled-components';
import { GlobalColors } from '../GlobalStyle';

type Props = {
  $size?: 'm' | 'l' | 'xl'
  $isDisabled?: boolean
  $status?: 'default' | 'error' | 'success'
  // $width: string;
  $readOnly?: boolean;
  $focus?: 'self' | 'within';
}

export const BaseInput = styled.div<Props>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  overflow: hidden;
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
  gap: 8px;
  border: 1px solid ${GlobalColors.border.primary};
  transition: ease-out 0.100ms;
  outline: none;
  background-color: ${GlobalColors.bg.main.secondary};

  &:hover {
    border: 1px solid ${GlobalColors.border.primaryHover};
  }
  
  ${({ $isDisabled }) => ($isDisabled && css`
    background: ${GlobalColors.bg.darkBlue.disable};
    border: none;
    pointer-events: none;
  `)};
    
  ${({ $focus = 'within' }) => {
    switch ($focus) {
      case 'self':
        return css`
          &:focus-within {
            box-shadow: 0 0 0 2px ${GlobalColors.border.focus};

            &:hover {
              border: 1px solid ${GlobalColors.border.primary};
            }
          }
        `;
      case 'within':
      default:
        return css`
          &:focus-within {
            box-shadow: 0 0 0 2px ${GlobalColors.border.focus};

            &:hover {
              border: 1px solid ${GlobalColors.border.primary};
            }
          }
        `;
    }
  }}
  
  ${({ $size }) => {
    switch ($size) {
      case 'l':
        return css`
            height: 40px;
            border-radius: 10px;
            font-size: 16px;
            line-height: 20px;
            & label { font-size: 14px; }
          `;
      case 'xl':
        return css`
            height: 48px;
            border-radius: 12px;
            font-size: 18px;
            line-height: 20px;
            & label { font-size: 16px; }
          `;
      case 'm':
      default:
        return css`
            height: 32px;
            border-radius: 8px;
            font-size: 14px;
            line-height: 18px;
            padding-right: 4px;
            & label { font-size: 12px; }
          `;
    }
  }}

  ${({ $status }) => {
    switch ($status) {
      case 'error':
        return css`
              border: 1px solid ${GlobalColors.border.error};
              color:  ${GlobalColors.text.error};
            `;
      case 'success':
        return css`
              border: 1px solid ${GlobalColors.border.success};
              color:  ${GlobalColors.text.success};
            `;
      default:
        return null;
    }
  }}
`;
