import { createContext } from 'react';

type ModalContext = {
    toggleVisibility: () => void
}

const ModalContext = createContext<ModalContext>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleVisibility: () => {},
});

export const ModalProvider = ModalContext.Provider;
export default ModalContext;
