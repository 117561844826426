import { useEffect, useState } from 'react';
import { emitter } from './events';

export const useFastlane = (src: string) => {
  const [url, setUrl] = useState<string | undefined>(src);

  useEffect(() => {
    setUrl(src);
    const unbind = emitter.on('load', (imageUrl) => {
      if (src === imageUrl) {
        const resultUrl = `${imageUrl}?update=${Date.now()}`;
        setUrl(resultUrl);
      }
    });

    return () => {
      unbind();
    };
  }, [src]);

  return { url, setFallback: setUrl };
};
