import { Dropdown } from '@mui/base/Dropdown';
import { Menu as MenuList } from '@mui/base/Menu';
import type { FC, ReactNode } from 'react';
import { StyledButton, StyledListbox, StyledMenuItem } from './styles';

type Props = {
  children: ReactNode
  button: ReactNode
}

const Menu: FC<Props> & { Item: typeof StyledMenuItem } = ({ button, children }) => {
  return (
    <Dropdown>
      <StyledButton>
        {button}
      </StyledButton>
      <MenuList slots={{ listbox: StyledListbox }}>
        {children}
      </MenuList>
    </Dropdown>
  );
};

Menu.Item = StyledMenuItem;

export default Menu;
