import styled, { css } from 'styled-components';
import type { InputSize, StatusType } from './helpers';
import { GlobalColors } from '../GlobalStyle';
import { Button } from '../Button';

interface SizeProps {
  $size?: InputSize;
}
interface DisabledProps {
  $isDisabled?: boolean;
}
interface StatusProps {
  $status?: StatusType;
}
interface WidthProps {
  $width: string;
}
interface ReadOnlyProps {
  $readOnly?: boolean;
}

export const Wrapper = styled.div<WidthProps>`
  position: relative;
  width: ${({ $width }) => $width};
`;

export const Message = styled.div<DisabledProps & StatusProps & ReadOnlyProps>`
    font-size: 12px;
    font-weight: 400;
    line-height: 1rem;
    margin-top: 4px;

  color: ${({ $status, $isDisabled, $readOnly }) => {
    switch (true) {
      case ($status === 'error' && !$isDisabled && !$readOnly):
        return GlobalColors.text.error;
      case ($status === 'success' && !$isDisabled && !$readOnly):
        return GlobalColors.text.success;
      case ($isDisabled):
        return GlobalColors.text.disable;
      default:
        return GlobalColors.text.primary;
    }
  }
};
`;

export const InputLine = styled.div`
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    gap: 4px;
`;

export const ButtonsGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
`;

export const ArrowGroup = styled.div<SizeProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    height: ${({ $size }) => {
    switch ($size) {
      // case 's':
      //   return '24px;';
      case 'm':
        return '32px;';
      case 'l':
        return '40px;';
      case 'xl':
        return '48px;';
      default:
        return '32px;';
    }
  }}
`;

export const ArrowButton = styled(Button)`
  width: 16px;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
`;

export const Label = styled.label<DisabledProps & StatusProps & ReadOnlyProps>`
    font-weight: 700;
    line-height: 18px;
    align-self: center;
    white-space: nowrap;

  color: ${({ $status, $isDisabled, $readOnly }) => {
    switch (true) {
      case ($status === 'error' && !$isDisabled && !$readOnly):
        return GlobalColors.text.error;
      case ($status === 'success' && !$isDisabled && !$readOnly):
        return GlobalColors.text.success;
      case ($isDisabled):
        return GlobalColors.text.disable;
      default:
        return GlobalColors.text.primary;
    }
  }
};
`;

export const InputField = styled.input`
    all: unset;
    box-sizing: border-box;
    width: 100%;
    position: relative;
    white-space: nowrap;
    color: ${GlobalColors.text.primary};
    background-color: transparent;
    text-overflow: ellipsis;
    border: 0;
    -moz-appearance: textfield;
  
    &:disabled {
      color: ${GlobalColors.text.disable};
    }

    &::placeholder {
      color: ${GlobalColors.text.ghost};
    }

    &:focus {
        border: 0;
        outline: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px ${GlobalColors.bg.main.secondary} inset !important;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
