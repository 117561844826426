import type { ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import { ResultBlock, ResultItem } from './styles';

type Props<T> = {
  searchResult: Array<T>
  setIsOpen: (isOpen: boolean) => void
  resultElement?: (item: T) => ReactNode
}

export const ResultList = <T extends Record<'id', string>>({ searchResult, setIsOpen, resultElement }: Props<T>) => {
  const { t } = useTranslation();

  return (
    <ResultBlock>
      {searchResult.map((item) => (
        <ResultItem key={item.id} onClick={() => setIsOpen(false)}>
          {resultElement ? resultElement(item) : t('search.foundNothing')}
        </ResultItem>
      ))}
    </ResultBlock>
  );
};
