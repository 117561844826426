import React, { FC, useMemo } from 'react';
import { Ellipsis } from '@gravity-ui/icons';
import {
  Container,
  Image,
  PictureList,
  SmallImage, StyledButton,
} from './styles';
import noImage from './no_image.png';

interface Props {
  images?: string[]
  onClick?: () => void
}

export const ImageGroup: FC<Props> = ({ images, onClick }) => {
  const imageLink = useMemo(() => (
    images && images?.length > 0 ? images[0] : null
  ), [images]);

  const list = images?.slice(1, 4);

  return (
    <Container>
      <Image src={imageLink || noImage.src} />
      {list && (
      <PictureList>
        {list.map((image) =>
          <SmallImage key={image} src={image} />)}
        {list.length > 0
            && (
            <StyledButton type="button" onClick={onClick}>
              <Ellipsis color="#989898" />
            </StyledButton>
            )}
      </PictureList>
      )}
    </Container>
  );
};
