import styled from 'styled-components';
import { BasePopup } from '../base';
// eslint-disable-next-line import/no-extraneous-dependencies

interface isOpenProps {
  $isOpen: boolean
}

export const Wrapper = styled.div<isOpenProps>`
  display: inline-block;
  position: ${({ $isOpen }) => ($isOpen ? 'absolute' : 'relative')};
  width: ${({ $isOpen }) => ($isOpen ? '100%' : 'auto')};
  max-width: 244px;
`;

export const InputStyle = styled.input`
  all: unset;
  background-color: transparent;
  font-size: 14px;
  flex: 1;
`;

export const ResultBlock = styled(BasePopup)`
  position: absolute;
  margin-top: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ResultItem = styled.button`
  all: unset;
  padding: 6px 8px;
  border-radius: 6px;
  cursor: pointer;
  
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
    
  &:hover {
    background-color: #F5F5F5;
  }
`;
