import {
  FC,
  ImgHTMLAttributes, useMemo,
} from 'react';
import { styled } from 'styled-components';
import Stub from './stub.svg';
import { useFastlane } from './hooks';

type ImageParams = Partial<{
  f: string // size (fit)
}>

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  src: string
  alt?: string
  fallback?: string
  params?: ImageParams
}

const Image = styled.img`
  object-fit: contain;
  height: 100%;
  width: auto;
  margin: 0 auto;
`;

export const LazyImage: FC<Props> = ({ src, alt = '', fallback, params, ...rest }) => {
  const { url, setFallback } = useFastlane(src);

  const resultUrl = useMemo(() => {
    if (params && !url?.includes('stub')) {
      const urlParams = new URLSearchParams(params);
      return `${url}?${urlParams}`;
    }

    return url;
  }, [params, url]);

  return (
    <Image
      alt={alt}
      loading="lazy"
      onError={() => { setFallback(fallback ?? Stub.src); }}
      src={resultUrl}
      {...rest}
    />
  );
};
