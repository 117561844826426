import styled, { css } from 'styled-components';
import { switchClasses } from '@mui/base/Switch';

type Size = 'small' | 'medium' | 'large'

interface SizeProps {
  $size: Size
}

export const Root = styled.span<SizeProps>`

  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          width: 28px;
          height: 16px;

          & .${switchClasses.thumb} {
            width: 12px;
            height: 12px;
            top: 2px;
            left: 2px;
          }

          &.${switchClasses.checked} {
            .${switchClasses.thumb} {
              left: 14px;
            }
          }
        `;

      case 'large':
        return css`
          width: 44px;
          height: 24px;

          & .${switchClasses.thumb} {
            width: 20px;
            height: 20px;
            top: 2px;
            left: 2px;
          }

          &.${switchClasses.checked} {
            .${switchClasses.thumb} {
              left: 22px;
            }
          }
        `;

      case 'medium':
      default:
        return css`
          width: 36px;
          height: 20px;

          & .${switchClasses.thumb} {
            width: 16px;
            height: 16px;
            top: 2px;
            left: 2px;
          }

          &.${switchClasses.checked} {
            .${switchClasses.thumb} {
              left: 18px;
            }
          }
        `;
    }
  }}
  
  font-size: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  
  &:hover {
    outline: 1px solid #99CAFE;
    border-radius: 16px;
  }

  &.${switchClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }

  & .${switchClasses.track} {
    background: #B2BBC6;
    border-radius: 24px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  }

  &.${switchClasses.focusVisible} .${switchClasses.track} {
    outline: 1px solid #99CAFE;
  }

  & .${switchClasses.thumb} {
    display: block;
    border-radius: 16px;
    background-color: #FFF;
    position: relative;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
    box-shadow: 0 2px 4px rgba(0, 35, 11, 0.20);
  }

  &.${switchClasses.checked} {
    .${switchClasses.thumb} {
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 35, 11, 0.20);
    }

    .${switchClasses.track} {
      border: none;
      background: #0D82FD;
    }

    &:hover {
      outline: 1px solid #99CAFE;
      border-radius: 16px;
    }
  }

  &:hover .${switchClasses.checked} .${switchClasses.track} {
    background: #0D82FD;
  }

  & .${switchClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;
