import { FC } from 'react';
import { ChevronsExpandVertical } from '@gravity-ui/icons';
import { SelectorListItem } from '../list';
import { SelectorStyle } from './styles';
import { Icon, SelectorItemIcon } from '../list/SelectorItemIcon';
import { StyledText } from '../list/styles';
import { BaseInput } from '../../base/BaseInput';

type ActiveValue = {
  icon: Icon
  value?: SelectorListItem
  placeholder?: string
}

export const SelectorButton: FC<ActiveValue> = ({ icon, value, placeholder }) => (
  <BaseInput $focus="self" role="button" tabIndex={0}>
    {icon && (value?.icon || value?.text) && <SelectorItemIcon type={icon} src={value?.icon} text={value?.text} />}
    <StyledText>
      {value?.text ?? placeholder}
    </StyledText>
    <ChevronsExpandVertical height={12} />
  </BaseInput>
);
