import { ChangeEvent, FC, InputHTMLAttributes, ReactNode, useRef } from 'react';
import { SwitchWrapper, Slider, SwitchInput, Icon } from './styles';

type Size = 'small' | 'medium'
type Color = 'light' | 'dark'

export interface SwitchIconProps extends InputHTMLAttributes<HTMLInputElement> {
  onChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>
  $size?: Size
  $color?: Color
  iconLeft: ReactNode
  iconRight: ReactNode
}

export const SwitchIcon: FC<SwitchIconProps> = ({
  onChange,
  $size = 'medium',
  $color = 'light',
  iconLeft,
  iconRight,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <SwitchWrapper $size={$size}>
      <SwitchInput
        ref={inputRef}
        type="checkbox"
        $size={$size}
        onChange={onChange}
        {...rest}
      />

      <Slider $color={$color} $size={$size} />

      <Icon
        style={{ left: '2px' }}
        $isActive={!inputRef.current?.checked}
        $size={$size}
        $color={$color}
      >
        {iconLeft}
      </Icon>

      <Icon
        style={{ right: '2px' }}
        $isActive={!!inputRef.current?.checked}
        $size={$size}
        $color={$color}
      >
        {iconRight}
      </Icon>
    </SwitchWrapper>
  );
};
