import { Magnifier } from '@gravity-ui/icons';
import type { FC } from 'react';
import { Button } from '../Button';

type Props = {
  setIsOpen: (isOpen: boolean) => void
}

export const SearchButton: FC<Props> = ({ setIsOpen }) => (
  <Button view="ghost" onClick={() => setIsOpen(true)}>
    <Magnifier color="#828282" />
  </Button>
);
